import React from 'react'

export const NameCell = (props) => (
  <td className="name-cell"
      onClick={() => {
        if (props.onClick) {
          props.onClick(props.dataItem)
        }}}>
    <span>{props.dataItem[props.field]}</span>
  </td>
)
