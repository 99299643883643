import { GridCellProps } from '@progress/kendo-react-grid'
import { copyToClipboard } from 'generic/utility'
import React from 'react'
import { SourceTypes } from 'shared_server_client/constants'

interface Source {
  category: string
  identifier: string
  name: string
  options: string
  sub_category: string
  type: SourceTypes
}

interface CopyToClipboardCellState {
  copied: boolean
}

interface CopyToClipboardCellProps extends GridCellProps {
  dataItem: Source
}

export class CopyToClipboardCell  extends React.Component<CopyToClipboardCellProps, CopyToClipboardCellState> {
  constructor(props: any) {
    super(props)
    this.state = {
      copied: false,
    }
  }
  public render() {
    const { identifier, options, type } = this.props.dataItem
    const url = type !== SourceTypes.facebook
    ? identifier
    : options ? JSON.parse(options).url : null
    return (
      <td>
        {!!url &&
        <div className="text-to-copy" onClick={this.handleClick}>
          {url}
        </div>}
        {this.state.copied &&
        <div className="copy-status">
          Copied
        </div>}
      </td>
    )
  }

  private handleClick = () => {
    copyToClipboard(this.props.dataItem.identifier)
    this.setState({copied: true})
    setTimeout(() => {
      this.setState({ copied: false })
    }, 1000)
  }
}
