export const ADD_SOURCES = 'addSources'
export const SEND_GA_EVENT = 'sendGAEvent'

export const exportImportFieldMapping = {
  name: 'Name',
  identifier: 'Url or @',
  type: 'Type',
  category: 'Category',
  sub_category: 'Sub Category',
}

export const ContentLibraryDialog = {
  title : 'Quick Tip',
  description : `<div>If you don't see sources that are relevant for your newsletter
  in the content library, feel free to add via RSS, Twitter, or other channels here.
  Or contact <a href='mailto:support@rasa.io?subject=Help with Newsletter Sources'>support@rasa.io</a> for source help!</div>`,
  }
