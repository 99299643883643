import { GridCellProps } from '@progress/kendo-react-grid'
import { startCase } from 'lodash'
import React from 'react'
import { Badge, Tooltip } from 'reactstrap'

interface TitleCellProps extends GridCellProps {
  onClick?: any
}

const MAX_TAGS: number = 5

interface Tag {
  className: string,
  tag: string,
}
export class TitleCell extends React.Component<TitleCellProps>  {
    private isTooltipOpen: boolean = false

    public render() {
      return (
      <td id={`Tags-${this.props.dataItem.id}`}
          onClick={this.click}>
        <div className="title-words">
          <b className="sourcetitle">
            <span>{this.props.dataItem[this.props.field]}</span>
          </b>
          <br></br>
          <p>
            <span dangerouslySetInnerHTML={{__html: this.props.dataItem.description }}></span>
          </p>
          <p className="tags">
            { this.allTags().slice(0, MAX_TAGS).map((tag: Tag, index: number) => {
              return <Badge className={tag.className}
                            key={`psd-${this.props.dataItem.id}-${index}`}
                            color="secondary">{startCase(tag.tag.toLowerCase())}</Badge>
            })}
          </p>
        </div>
        {this.hasFeedback() &&
        <Tooltip
          autohide={true}
          placement="right-end"
          toggle={this.toggle}
          isOpen={this.isTooltipOpen}
          target={`Tags-${this.props.dataItem.id}`}
          className="filter-matches-tooltip">
        {this.props.dataItem.whitelist_match &&
        <span><strong>Include Matches: </strong>{this.format(this.props.dataItem.whitelist_match)}<br/></span>}
        {this.props.dataItem.blacklist_match &&
        <span><strong>Exclude Matches: </strong>{this.format(this.props.dataItem.blacklist_match)}</span>}
        </Tooltip>}
      </td>
      )
    }

    private click = (e: any) => {
      if (this.props.onClick) {
        this.props.onClick(this.props.dataItem)
      }
    }

    private allTags = (): Tag[] => {
      return this.asTags(this.props.dataItem.custom_tags, 'custom')
                 .concat(this.asTags(this.props.dataItem.nlp_tags, 'nlp'))
                 .concat(this.asTags(this.props.dataItem.source_tags, 'source'))
    }

    private asTags = (tagList: string, className: string): Tag[] => {
      return (tagList || '').split(',')
                            .filter((tag: string) => tag.length > 0)
                            .map((tag: string) => ({ className, tag}))
    }

    private hasFeedback = (): boolean => {
      return this.props.dataItem.whitelist_match || this.props.dataItem.blacklist_match
    }

    private toggle = () => {
      this.isTooltipOpen = !this.isTooltipOpen
      this.setState({})
    }

    private format = (match: string) => {
      return match.split(',').join(', ')
    }

  }
