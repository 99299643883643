
import { DropdownComponent, DropdownOption } from 'components/dropdown/component'
import { Loading } from 'components/loading'
import React from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'
import * as Modals from 'shared/modals'
import { SourceSection } from './types'

export class UpcomingArticlesModal extends Modals.ModalComponent {
  public static key: string = 'upcomingArticlesModal'
  constructor(props: Modals.ModalComponentProps) {
    super(props, UpcomingArticlesModal.key)
  }
  protected renderChildren(data: any) {
    return (
      <span>
        Oh no, you don't have enough content.
        You need to have at least {data.minimumArticlesCount} articles to send your newsletter.
      </span>
    )
  }
}

interface SectionModalState extends Modals.ModalComponentState {
  section?: string,
  selectedSection: number,
}

interface SectionModalProps extends Modals.ModalComponentProps {
  sections?: SourceSection[],
  section?: string,
  onSave: (section: number, postSubscriptionId: number) => Promise<any>
}

export class SectionModalComponent extends Modals.GenericModalComponent<SectionModalProps, SectionModalState> {
  public static key: string = 'updateArticleSection'
  constructor(props: SectionModalProps) {
    super(props, SectionModalComponent.key, 'Update Section')
    this.state = {
      section: props.section,
      selectedSection: props.sections.length ? Number(props.sections[0].category_type_id) : 0,
      isSaving: false,
    }
  }

  public componentWillReceiveProps(nextProps) {
    const newSection = nextProps.data[SectionModalComponent.key].data.section
    if (newSection && newSection !== this.props.data[SectionModalComponent.key].data.section) {
      this.setState({selectedSection: this.props.sections.filter((x) => x.name === newSection)[0].category_type_id});
    }
  }

  protected renderChildren(data: any) {
    return (
      <div className="article-section-modal-body">
        {this.state.isSaving
        ? <Loading size="64"/>
        : <div>
            <h4>Section</h4>
            <DropdownComponent
                data={this.normalizeSections()}
                selected={this.state.selectedSection ? this.state.selectedSection : null}
                onChange={this.sectionChanged}/>
          </div>
        }
      </div>
    )
  }

  protected doSave(data: any) {
    return this.props.onSave(this.state.selectedSection, data.post_subscription_id).then(() => {
      this.setState({
        isSaving: false,
        section: null,
        selectedSection: this.props.sections.length ? Number(this.props.sections[0].category_type_id) : 0,
      })
    });
  }

  protected xButtonClick(data: any) {
    this.setState({
      selectedSection: this.props.sections.length ? Number(this.props.sections[0].category_type_id) : 0,
    })
    this.props.closeModal(SectionModalComponent.key)
  }

  private sectionChanged = (e: any) => {
    this.setState({selectedSection: e.selected.key})
  }

  private normalizeSections = (): DropdownOption[] => {
    const normalizedSections = this.props.sections.filter((x) => x.category_type_id !== 0).map((s: any) => ({
      description: s.name,
      key: s.category_type_id,
      value: s.category_type,
    }))
    normalizedSections.unshift({description: 'No section', key: 1, value: 1})
    return normalizedSections
  }
}

export const SectionModal = connect(
  null,
  {
    push: Router.push,
  },
)(SectionModalComponent)
