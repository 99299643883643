import { GridCellProps } from '@progress/kendo-react-grid'
import { LighteningEmpty } from 'components/icons/lightening3'
import { ScheduledContentIcon } from 'components/icons/scheduled-content'
import React from 'react'
import { NORMAL_BOOSTED_BOOST_LEVEL, SUPER_BOOSTED_BOOST_LEVEL } from 'shared_server_client/constants'
import { LighteningFull } from '../../icons/lightening1'
import { LighteningActive } from '../../icons/lightening2'

export class BoostTypeCell extends React.Component<GridCellProps> {
  constructor(p: GridCellProps) {
    super(p)
  }

  public render() {
    return <td>
      {!this.props.dataItem.is_archived &&
      <div className="toggle-active boost-cell">
        {this.props.dataItem.boost_level === NORMAL_BOOSTED_BOOST_LEVEL ?
          <LighteningFull/>
        : this.props.dataItem.boost_level === SUPER_BOOSTED_BOOST_LEVEL ?
          <LighteningActive/>
        : this.props.dataItem.scheduled_article_id ?
          <ScheduledContentIcon svgwidth={30} svgheight={30}/>
        : this.props.dataItem.is_reserved ?
          <i className="fa fa-retweet"></i>
        : <LighteningEmpty/>
        }
      </div>}
    </td>
  }
}
