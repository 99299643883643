import {
  DropdownComponent,
  DropdownOption,
  OnChange,
  OnChangeEvent,
} from 'components/dropdown/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import React from 'react'
import * as Modals from 'shared/modals'
import * as GenerativeAI from '../../../../shared_server_client/types/generative_ai'
import * as Categories from '../../../../shared/data-layer/categories'
import * as DomainTopics from '../../../../shared/data-layer/domain_topics'
import './_styles.scss'
import * as Types from './types'

const PICK_ONE: DropdownOption = {
  description: ' - Pick one -',
  key: '0',
}

const ADD_PARENT_ONLY: DropdownOption = {
  description: ' - Add Parent Category -',
  key: '-100000',
}

const ANALYZE_TYPES: DropdownOption[] = [
  PICK_ONE,
  {
    description: 'Analyze as an RSS feed',
    key: GenerativeAI.AnalyzeType.RSS.valueOf(),
  },
  {
    description: 'Analyze as an article',
    key: GenerativeAI.AnalyzeType.URL.valueOf(),
  },
]

const GPT_VERSIONS: DropdownOption[] = [
  {
    description: 'ChatGPT 3.5',
    key: "openai:gpt-3.5-turbo"
  },
  {
    description: 'ChatGPT 4.0',
    key: "openai:gpt-4-turbo-preview"
  },
  {
    description: 'Mistral Tiny',
    key: "mistral:mistral-tiny"
  },
  {
    description: 'Mistral Small',
    key: "mistral:mistral-small"
  },
  {
    description: 'Mistral Large',
    key: "mistral:mistral-large"
  },
]

const EMPTY_CATEGORY: Categories.Category = {
  id: 0,
  name: '',
  rank: 0,
  source_count: 0,
}

export class SourceContentLibraryModal
  extends Modals.GenericModalComponent<Types.ModalProps, Types.ModalState> {

  public static key: string = 'sourceContentLibrary'
  public static contextType = RasaContext

  public static defaultProps = {
    saveModal: () => true,
    secondAction: Modals.ModalActionType.BUTTON,
  }

  constructor(props: Types.ModalProps) {
    super(props, SourceContentLibraryModal.key, 'Add Source to Content Library')
  }

  public componentDidMount() {
    this.context.user.init().then(({activeCommunity}) => {
      if (activeCommunity.billingInfo && activeCommunity.billingInfo.currentPlan) {
        this.setState({
          communityId: activeCommunity.communityId,
          selectedVersion: GPT_VERSIONS[0],
          selectedType: ANALYZE_TYPES[0],
        }, () => this.fetchTopics())
      }
    })
  }

  protected saveDisabled(): boolean {
    return (this.state.selections || []).length === 0
  }

  protected doSave(data: any): Promise<any> {
    return this.context.entityMetadata.getEntityObject('source').then((source) => {
      source.setRecordId(this.state.communityId, this.getData().id)
      source.data.categories = this.state.selections.map((s: Types.Selection) => {
        if ( s.subCategory ) {
          return {
            category: s.subCategory.value,
            parent: s.category.value,
          }
        } else {
          return {
            category: s.category.value,
            parent: null,
          }
        }
      })
      return source.save()
        .then(() => {
          this.setState({
            isSaved: true,
            isSaving: false,
          })
          return true
        })
        .catch((error) => {
          this.setState({
            error: `${error}`,
            isSaving: false,
          })
          return false
        })
      })
  }

  protected renderChildren(data: Types.ModalState) {
    const subCategories: Types.AICategory[] = this.subcategories()
    return <div>
        <div>
          <span className='identifier'>{data.identifier}</span>
        </div>
        <div>
          <span className="title">How should the article be analyzed?</span>
        </div>
        <div>
          <DropdownComponent className="half-width" data={ANALYZE_TYPES}
                            selected={this.state.selectedType ? this.state.selectedType.key : PICK_ONE.key}
                            onChange={this.analyzeTypeSelected}/>
          <DropdownComponent className="half-width" data={GPT_VERSIONS}
                            selected={this.state.selectedVersion ? this.state.selectedVersion.key : GPT_VERSIONS[0].key}
                            onChange={this.analyzeVersionSelected}/>
        </div>
      { this.state.isLoading ? <Loading size="64"/> :  <div>
        {(this.state.aiCategories || []).length > 0 &&
          <div className="categories">
            <span className="title">Choose which category this source best fits</span>
            <DropdownComponent data={this.categoriesAsDropdownOptions([PICK_ONE], this.state.aiCategories)}
                              selected={this.state.selectedCategory ? this.state.selectedCategory.key : PICK_ONE.key}
                              onChange={this.categorySelected}/>

          </div>
        }
        { subCategories.length > 0 &&
            <div className="categories">
              <span className="title">Choose which subcategory this source best fits</span>
              <DropdownComponent data={this.categoriesAsDropdownOptions([PICK_ONE, ADD_PARENT_ONLY], subCategories)}
                                selected={null}
                                onChange={this.subCategorySelected}/>

            </div>
        }
        { this.state.selections && <div>
          <div className="box-separator"></div>
          <span className="title">Selected Categories</span>
          { this.state.selections.map((s: Types.Selection, index: number) => (
            <div className="selected" key={`selection-${index}`}>
              <i className="fa fa-trash fa-2 clickable-item" onClick={() => this.removeSelection(index) }/>&nbsp;
              <span>{s.category.description} / {s.subCategory ? s.subCategory.description : ''}</span>
            </div>
          ))}
        </div>}
        { this.state.error && <div className="error">
          <div className="box-separator"></div>
          <span>{this.state.error}</span>
        </div>}
        <div className="box-separator"></div>
        {this.state.summary &&
          <div className="summary">
            <div className="title">Summary</div>
            <span>{this.state.summary}</span>
          </div>
        }
        { this.state.isSaved && <div className="saved">
          <div className="box-separator"></div>
          <span>Data has been saved.</span>
        </div>}
      </div> }
    </div>
  }

  protected onModalClose = (data: any) => {
    this.setState({
      aiCategories: [],
      aiTopics: [],
      error: null,
      isLoading: false,
      isSaved: false,
      isSaving: false,
      selectedType: null,
      selections: [],
      summary: null,
    }, () => super.onModalClose(data))
  }

  private removeSelection(index: number) {
    this.setState({
      selections: this.state.selections.filter((s: Types.Selection, i: number) => i !== index),
    })
  }

  private categoriesAsDropdownOptions(
    baseCategories: DropdownOption[], categories: Types.AICategory[]
  ): DropdownOption[] {

    const sorted = categories.sort((a: Types.AICategory, b: Types.AICategory) => {
      if ( b.relevance !== a.relevance ) {
        return b.relevance - a.relevance
      } else if ( b.source_count !== a.source_count ) {
        return b.source_count - a.source_count
      } else {
        return a.name.localeCompare(b.name)
      }
    })

    return baseCategories.concat(sorted.map((c: Types.AICategory, index: number) => ({
      description: `${c.source || ''} ${c.name} - ${c.source_count} (${c.relevance})`.trim(),
      key: c.id > 0 ? c.id : -1 * (index+1),
      value: c.name,
    })))
  }

  private subcategories(): Types.AICategory[] {
    if (!this.state.selectedCategory) {
      return []
    }

    const relevantSubcategories = this.props.subCategories.filter((c: Categories.SubCategory) => {
      return c.parent.id === this.state.selectedCategory.key
    }).map((c: Categories.SubCategory) => ({
      ...EMPTY_CATEGORY,
      id: c.id,
      name: c.name,
      relevance: 0,
      source: '',
    }))

    return this.state.aiTopics.reduce((results: Types.AICategory[], c: Types.AICategory) => {
      const existing = results.find((a: Types.AICategory) => a.name === c.name)
      if ( existing ) {
        existing.relevance = c.relevance
        existing.source = c.source
      } else {
        results.push(c)
      }
      return results
    }, relevantSubcategories)
  }

  private analyzeVersionSelected: OnChange = (e: OnChangeEvent) => {
    const state: Types.ModalState = this.state
    if ( !state.selectedVersion || ( e.selected.key !== state.selectedVersion.key ) ) {
      this.setState({
        aiCategories: [],
        aiTopics: [],
        error: null,
        isLoading: true,
        selectedVersion: e.selected,
      }, () => this.fetchTopics())
    }
  }

  private analyzeTypeSelected: OnChange = (e: OnChangeEvent) => {
    const state: Types.ModalState = this.state
    if ( !state.selectedType || ( e.selected.key !== state.selectedType.key ) ) {
      this.setState({
        aiCategories: [],
        aiTopics: [],
        error: null,
        isLoading: true,
        selectedType: e.selected,
      }, () => this.fetchTopics())
    }
  }

  private fetchTopics = () => {
    if ( this.getData().identifier ) {
      const parts = this.state.selectedVersion.key.toString().split(':')

      const query: DomainTopics.Query = {
        includeSummary: 1,
        rank: 2,
        type: this.state.selectedType.key.toString(),
        engine: parts[0],
        version: parts[1],
        url: this.getData().identifier,
      }
      return DomainTopics.getDomainTopics(query)
        .then((response: DomainTopics.DomainTopics) => {
          this.setState({
            aiCategories: this.props.categories.map((c: Categories.Category) => {
              const aiCategory = response.categories.find((r: GenerativeAI.Keyword) => r.text === c.name)
              const relevance = aiCategory ? aiCategory.weight : 0
              return {
                ...c,
                relevance,
              }
            }),
            aiTopics: response.topics.map((t: GenerativeAI.Keyword) => ({
              ...EMPTY_CATEGORY,
              id: -1,
              name: t.text,
              relevance: t.weight,
              source: t.source,
            })),
            error: response.error,
            isLoading: false,
            summary: response.summary,
          })
        }).catch((ex) => {
          this.setState({
            error: `${ex}`,
            isLoading: false,
          })
        })
    }
  }

  private subCategorySelected: OnChange = (e: OnChangeEvent) => {
    if ( e.selected.key === PICK_ONE.key) {
      return
    } else if ( e.selected.key === ADD_PARENT_ONLY.key ) {
      const selection: Types.Selection = {
        category: this.state.selectedCategory,
        subCategory: null,
      }
      this.addSelection(selection)
    } else {
      const selection: Types.Selection = {
        category: this.state.selectedCategory,
        subCategory: e.selected,
      }
      this.addSelection(selection)
    }
  }

  private addSelection = (selection: Types.Selection) => {
    const existing: Types.Selection = (this.state.selections || []).find((s: Types.Selection) => {
      return s.category.key === selection.category.key &&
             (
              (!s.subCategory && !selection.subCategory) ||
              ( s.subCategory && selection.subCategory && s.subCategory.key === selection.subCategory.key )
             )
    })
    if (!existing) {
      const newSelections: Types.Selection[] = ( this.state.selections || [] ).concat([selection])
      this.setState({
        selections: newSelections,
      })
    }
  }

  private categorySelected: OnChange = (e: OnChangeEvent) => {
    if ( e.selected.key === PICK_ONE.key) {
      return
    } else {
      this.setState({
        selectedCategory: e.selected
      })
    }
  }
}
