import { GridCell } from '@progress/kendo-react-grid'
import { isEmpty } from 'lodash'
import React from 'react'

export class SourceCell extends GridCell {
    public render() {
      return (
        <td className="source-words">
          <div>
            <b>{this.props.dataItem.site_name}</b>
          </div>
          {!isEmpty(this.props.dataItem.source_name) &&
            this.props.dataItem.site_name !== this.props.dataItem.source_name &&
          <div>
            <br></br>
            <p>
              {this.props.dataItem.source_name}
            </p>
          </div>}
        </td>
      )
    }
  }
