import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as React from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'
import * as Modals from 'shared/modals'
import { isValidSectionName } from '../utility/utils'
interface AddSourceSectionState extends Modals.ModalComponentState {
  name: string,
}

interface AddSourceSectionProps extends Modals.ModalComponentProps {
  sectionData: any,
  push: any,
  name?: string,
  createOrUpdate: (payload: any) => Promise<any>
}

export class AddSourceSectionModalComponent extends Modals.GenericModalComponent<AddSourceSectionProps,
  AddSourceSectionState> {
  public static contextType = RasaContext
  public static key: string = 'addSourceSection'
  public static defaultProps = {
    closeButtonText: 'Close',
    saveText: 'Save',
    secondAction: Modals.ModalActionType.BUTTON,
    title: 'Add a Section',
  }
  private isSaveButtonDisabled: boolean = false
  constructor(props: AddSourceSectionProps) {
    super(props, AddSourceSectionModalComponent.key, 'Add a section')
    this.state = {
      name: props.name,
      isSaving: false,
    }
  }

  public componentWillReceiveProps(nextProps) {
    // reset the timer if children are changed
    if (nextProps.name && nextProps.name !== this.props.name) {
      this.setState({name: nextProps.name});
    }
  }

  protected renderChildren(data: any) {
    this.isSaveButtonDisabled = isValidSectionName(this.props.sectionData, this.state.name)
    return (
      <div className="add-section-modal-body">
        {this.state.isSaving
        ? <Loading size="64"/>
        : <div>
            <h4>Name</h4>
            <input
              type="text"
              value={this.state.name}
              placeholder="Enter section name..."
              onChange={(e) => {
                e.preventDefault()
                this.setState({ name: e.target.value })
              }}
            />
          </div>
        }
        <div>
        { this.isSaveButtonDisabled
        ? <div className="invalid-source-name-container">You already have a section with this name</div>
        : null}
        </div>
      </div>
    )
  }

  protected close = (data: any) => {
    return this.props.closeModal(AddSourceSectionModal.key)
  }

  protected saveDisabled(data: any): boolean {
    return this.state.name === '' ? true : this.isSaveButtonDisabled
  }

  protected doSave(data: any) {
    return this.props.createOrUpdate({name: this.state.name}).then(() => {
      this.setState({
        isSaving: false,
        name: '',
      })
    });
  }
}

export const AddSourceSectionModal = connect(
  null,
  {
    push: Router.push,
  },
)(AddSourceSectionModalComponent)
