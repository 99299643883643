import { AnchorLink } from 'components/buttons'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { AddSource } from 'components/icons/addSource'
import { Books } from 'components/icons/books'
import { FacebookIcon } from 'components/icons/facebook'
import { InstagramIcon } from 'components/icons/instagram'
import { LinkedInIcon } from 'components/icons/linkedin'
import { NextIcon } from 'components/icons/next'
import { RSSFeedIcon } from 'components/icons/rssfeed'
import { UpgradeAnchorLink } from 'components/link'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { registerNewNestedComponent } from 'generic/genericRedux'
import * as GA from 'google-analytics'
import { orderBy } from 'lodash'
import React from 'react'
import { Row } from 'reactstrap'
import { CommunitySystem, getCommunityIntegrations, IntegrationSystems } from 'shared/data-layer/integration'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { SourceTypes } from 'shared_server_client/constants'
import * as BillingPlan from 'shared_server_client/types/billing_plan'
import * as Constants from '../constants'
import { ContentPoolAddTwitterSource } from '../twitter/add-source'
import './_styles.scss'

const isIntegrationSupported = false

enum SourceSelected {
  none,
  facebook,
  rss,
  twitter,
}
interface AddSourceState {
  accountSourcesCount: number,
  isLoading: boolean,
  plan: BillingPlan.BillingPlan,
  selected: SourceSelected,
}

export class MobileContentPoolAddSourceComponent extends React.Component<any, AddSourceState> {
  public static contextType = RasaContext;
  private sharedStore: SharedStore

  constructor(props: any) {
    super(props)
    this.state = {
      accountSourcesCount: 0,
      isLoading: true,
      plan: null,
      selected: SourceSelected.none,
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
    ])
    .then(([activeCommunity, person]) => {
      this.getSources(activeCommunity.communityId)
      .then((orderedSources) => {
        this.props.datasetLoaded(orderedSources)
        this.setState({
          accountSourcesCount: activeCommunity.billingInfo.usageStats.sourcesCount,
          isLoading: false,
          plan: activeCommunity.billingInfo.currentPlan,
        })
      })

      if (activeCommunity.billingInfo && activeCommunity.billingInfo.currentPlan) {
        this.setState({plan: activeCommunity.billingInfo.currentPlan})
      }
      this.getSources(activeCommunity.communityId)
    })
  }

  public render() {
    if (this.state.isLoading) {
      return (<Loading size="64" />)
    }
    const maxSourceCount = BillingPlan.getCurrentPlanMaxSources(this.state.plan)
    if (this.state.accountSourcesCount >= maxSourceCount) {
      return (
        <div className="inline-row">
          <div className="add-sources-usage-container">
            You've reached your limit of <b>{maxSourceCount} sources</b>
            <br/>
            <UpgradeAnchorLink source={GA.UpgradeSource.AddSource}>
              Upgrade
            </UpgradeAnchorLink> to unlock more
          </div>
        </div>)
    }
    return (
      <div className="add-source-wrapper">
      <Row>
        <Row>
          { this.state.selected === SourceSelected.none &&
          <SourceSelectorComponent selectSource={this.selectSource}/>}
          { this.state.selected === SourceSelected.twitter &&
          <ContentPoolAddTwitterSource/> }
        </Row>
      </Row>
    </div>
    )
  }

  private selectSource = (selected: SourceSelected) => {
    this.setState({
      ...this.state,
      selected,
    })
  }

  private getSources = (communityIdentifier) => {
    const url: string = AjaxWrapper.getServerUrl() + `/dataset/${communityIdentifier}/communitySources`
    return AjaxWrapper.ajax(url, HttpMethod.GET, null)
      .then((response) => {
        return orderBy(response[0], ['is_active'], 'desc')
      })
  }
}

export const MobileContentPoolAddSource = registerNewNestedComponent(
  MobileContentPoolAddSourceComponent, 'add_source', {})

interface SourceSelectorProps {
  selectSource: any,
}

interface SourceSelectorState {
  activeCommunity: any,
  instagramSystem: CommunitySystem,
  linkedInSystem: CommunitySystem,
  isLoading: boolean,
  person: any,
}

class SourceSelectorComponent extends React.Component<SourceSelectorProps, SourceSelectorState> {
  public static contextType = RasaContext;
  private sharedStore: SharedStore

  constructor(props: SourceSelectorProps) {
    super(props)

    this.state = {
      activeCommunity: null,
      instagramSystem: null,
      linkedInSystem: null,
      isLoading: true,
      person: null,
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
    ])
    .then(([activeCommunity, person]) => {
      this.setState({activeCommunity, person })
      getCommunityIntegrations(activeCommunity, person)
      .then((allIntegrations: CommunitySystem[]) => {
        this.setState({
          instagramSystem: allIntegrations.find((i: CommunitySystem) => i.name === IntegrationSystems.Instagram),
          isLoading: false,
          linkedInSystem: allIntegrations.find((i: CommunitySystem) => i.name === IntegrationSystems.LinkedIn),
         })
      })
    })
  }

  public render = () => (
    this.state.isLoading ? <Loading size="64" /> :
    <div className="mobile-source-add-icon">
        <Row>
            <span className="header-image-mobile">
            <AddSource svgwidth={45} svgheight={45}/>
            </span>
            <h4>Add New Content</h4>
        </Row>
      <Row>
        <div className="blog mobile-sources">
        <AnchorLink href={`${DashboardMenuOption.content}/add-rss`}>
          <div className="image">
            <RSSFeedIcon/>
          </div>
          <div className="words">
            <h1>Blog or RSS feed</h1>
            <p>Showcase links or articles posted on a Blog or plug in any RSS feed.</p>
          </div>
          <div className="nexticon">
            <NextIcon/>
          </div>
        </AnchorLink>
        </div>
      </Row>
      <Row>
        <div className="facebook mobile-sources">
          <AnchorLink href={`${DashboardMenuOption.content}/add-facebook`}>
            <div className="image">
              <FacebookIcon svgwidth={50} svgheight={50}/>
            </div>
            <div className="words">
              <h1>Facebook</h1>
              <p>Showcase links or articles you've posted on your Facebook Page.</p>
            </div>
            <div className="nexticon">
              <NextIcon/>
            </div>
          </AnchorLink>
        </div>
      </Row>
      { isIntegrationSupported && this.state.instagramSystem ?
      <Row>
        <div className="instagram mobile-sources">
          <AnchorLink href={this.getInstagramHref()}>
            <div className="image">
              <InstagramIcon svgwidth={50} svgheight={50}/>
            </div>
            <div className="words">
              <h1>{SourceTypes.instagram}</h1>
              <p>Showcase links or articles you've posted on your Instagram.</p>
            </div>
            <div className="nexticon">
              <NextIcon/>
            </div>
          </AnchorLink>
        </div>
      </Row>
      : null
      }
      { isIntegrationSupported && this.state.linkedInSystem ?
      <Row>
        <div className="linkedin mobile-sources">
          <AnchorLink href={this.getLinkedInHref()}>
            <div className="image">
              <LinkedInIcon svgwidth={50} svgheight={50}/>
            </div>
            <div className="words">
              <h1>{SourceTypes.linkedIn}</h1>
              <p>Showcase links or articles you've posted on your Organization's LinkedIn.</p>
            </div>
            <div className="nexticon">
              <NextIcon/>
            </div>
          </AnchorLink>
        </div>
      </Row>
      : null
      }
      <Row>
        <div className="contentlib mobile-sources">
        <AnchorLink href={`${DashboardMenuOption.content}/library`}>
          <div className="image">
            <Books svgwidth={16.48} svgheight={16.48}/>
          </div>
          <div className="words">
            <h1>Content Library</h1>
            <p>Need help finding sources to add? Explore our Content Library here!</p>
          </div>
        </AnchorLink>
        </div>
      </Row>
    </div>)

  private getInstagramHref = () => {
    if (this.state.instagramSystem) {
      const addInstagramPage = Constants.CONTENT_ADD_INSTAGRAM_PAGE
      if (this.state.instagramSystem.is_active) {
        return addInstagramPage
      } else {
        if (this.state.instagramSystem.connected) {
          return `${Constants.INTEGRAGTIONS_CONFIG_PAGE}?id=${this.state.instagramSystem.community_system_id}&ref=${addInstagramPage}`
        } else {
          return `${Constants.INTEGRAGTIONS_CONFIG_PAGE}?systemName=${this.state.instagramSystem.name}&displayName=${this.state.instagramSystem.display_name}&ref=${addInstagramPage}`
        }
      }
    }
  }
  private getLinkedInHref = () => {
    if (this.state.linkedInSystem) {
      const addLinkedInPage = Constants.CONTENT_ADD_LINKEDIN_PAGE
      if (this.state.linkedInSystem.is_active) {
        return addLinkedInPage
      } else {
        if (this.state.linkedInSystem.connected) {
          return `${Constants.INTEGRAGTIONS_CONFIG_PAGE}?id=${this.state.linkedInSystem.community_system_id}&ref=${addLinkedInPage}`
        } else {
          return `${Constants.INTEGRAGTIONS_CONFIG_PAGE}?systemName=${this.state.linkedInSystem.name}&displayName=${this.state.linkedInSystem.display_name}&ref=${addLinkedInPage}`
        }
      }
    }
  }
}
