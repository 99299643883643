import { GridCellProps, GridColumn as Column } from '@progress/kendo-react-grid'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { HeaderComponent } from 'components/header/component'
import { SearchIcon } from 'components/icons/searchicon'
import { RasaContext } from 'context'
import { BaseClientEntity } from 'generic/baseClientEntity'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { PagingSource, RasaDataGrid } from 'generic/rasaDataGrid'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { isEmpty } from 'lodash'
import * as React from 'react'
import { Button, Row } from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { DateTimeCell } from '../kendocells'
import { isValidSectionName } from '../utility/utils'
import { ToggleAssignmentCell } from './kendocells'
import './styles.scss'

interface EditSectionState {
  editName: boolean,
  name: string,
  search: string,
  sectionId: number,
  existingSections: any[],
  isSaveButtonDisabled: boolean,
  isDisplayErrorMsg: boolean,
}
class EditSectionComponent extends RasaReactComponent<any, EditSectionState> {
  public static contextType = RasaContext;
  private communityId: string = null
  private sharedStore: SharedStore
  constructor(p: any) {
    super(p, 'section')
    this.state = {
      editName: false,
      isDirty: false,
      isLoading: false,
      isSaving: false,
      name: '',
      search: '',
      sectionId: null,
      existingSections: [],
      isSaveButtonDisabled: true,
      isDisplayErrorMsg: false,
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
    ]).then(([activeCommunity]) => {
      this.communityId = activeCommunity.communityId
      this.loadSections()
    })
    const firstId = this.context.store.getState().app.params.id
    this.setState({sectionId: Number(firstId)})
    this.loadOnRouting(firstId, `${DashboardMenuOption.content}/editsection`)
  }

  public render = () => {
    return <div className="edit-section">
    <Row>

    </Row>
    <div className="header-container">
      <HeaderComponent
        title={'SECTIONS'}
        subTitle={'Edit Section'}
      />
    </div>
    <Row>
      <div className="section-box">
        <div className="text">
          <strong>Section</strong>
          <span className="rename" onClick={this.intoEditMode}>Rename</span>
        </div>
        {this.state.editName
        ? <div className="section-edit-container">
          <input autoFocus className="field-textbox"
                  value={this.state.name}
                  onChange={(e) => this.sectionNameOnChange(e.target.value)} />
          <Button className="save"
                  onClick={this.saveSection}
                  disabled={this.state.isLoading || this.state.isSaveButtonDisabled}
                  >Save</Button>
          <Button className="cancel"
                  onClick={() => this.setState({editName: false, name: this.props.data.name})}>Cancel</Button>
        </div>
        : <div className="text grey">
          {this.props.data.name}
        </div>
        }
        {this.state.isDisplayErrorMsg &&
        <div className="invalid-source-name-container">You already have a section with this name</div>
        }
      </div>
    </Row>
    <Row>
      <div className="filters-section">
        <h5>Sources for this Section</h5>
        <p>Active or deactivate sources for this section</p>
      </div>
    </Row>
    <Row>
      <div className="search-bar">
          <label>
            <SearchIcon/>
            <input type="text"
                  value={this.state.search}
                  placeholder="Search..."
                  onChange={(e) => this.setSearch(e.target.value)}/>
          </label>
      </div>
        <RasaDataGrid
            entityName="communitySource"
            datasetName="subscriptionsBySection"
            datasetParams={[
              { param: 'section_idVal', value: this.state.sectionId },
            ]}
            editDefaultState={false}
            gridFilter={this.filterData}
            pagingSource={PagingSource.local}
            pageable={true}
            pageSize={25}
            resizable={true}
            sortable={true}
            data={[]}>
          <Column field="is_section_assigned" title="Assigned?" cell={this.SectionAssignmentToggleCell} />
          <Column field="source_type" title="Source" editable={false}/>
          <Column field="name" title="Name" editable={false}/>
          <Column field="last_week_total" title="# Upcoming" editable={false} width="95%"/>
          <Column field="created" title="Created" editable={false} cell={DateTimeCell}/>
        </RasaDataGrid>
      </Row>
    </div>
  }
  protected filterData = (s: any): boolean => {
    return this.state.search.length <= 3 || (
      (s.name && s.name.indexOf(this.state.search) >= 0) ||
      (s.source_type && s.source_type.indexOf(this.state.search) >= 0) ||
      (s.source_url && s.source_url.indexOf(this.state.search) >= 0)
    )
  }
  protected setSearch = (s: string) => {
    this.setState({
      search: s,
    })
  }
  private intoEditMode = () => {
    this.sectionNameOnChange(this.props.data.name)
    this.setState({editName: true, name: this.props.data.name})
    }

  private sectionNameOnChange = (sectionName) => {
    const showErrorMessage = isValidSectionName(this.state.existingSections, sectionName, this.state.sectionId)
    let saveButtonDisabled = false
    if (isEmpty(sectionName)) {
      saveButtonDisabled = true
    }
    this.setState({name: sectionName, isSaveButtonDisabled: saveButtonDisabled, isDisplayErrorMsg: showErrorMessage})
  }
  private toggleSectionAssignment = (id: number, isAssign: boolean) => {
    return this.context.entityMetadata
    .getEntityObject('subscriptionSection')
    .then((subscriptionSectionEntityObject: BaseClientEntity) => {
      subscriptionSectionEntityObject.setRecordId(this.communityId, null)
      subscriptionSectionEntityObject.data.section_id = this.state.sectionId
      subscriptionSectionEntityObject.data.subscription_id = id
      subscriptionSectionEntityObject.data.is_active = isAssign
      return subscriptionSectionEntityObject.save()
    })
  }

  private SectionAssignmentToggleCell = (props: GridCellProps) => {
    return <ToggleAssignmentCell {...props} onToggle={this.toggleSectionAssignment}/>
  }
  private loadSections = () => {
    return new Dataset().loadCommunityDataset('sections',  this.communityId)
      .then((response) => {
         this.setState({existingSections: response[0]})
      })
  }
  private saveSection = () => {
    this.propertyChanged('name', this.state.name)
    this.setState({
      isLoading: true,
    }, () => {
      this.saveRecord(this.props.data.id)
        .then(() => this.setState({ isLoading: false }))
        .then(() => {
          this.setState({editName: false})
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(`Error while saving, Error = ${error}`)
          this.setState({ isLoading: false })
        })
    })
  }
}
export const ContentPoolEditSection = GenericRedux.registerNewNestedComponent(
  EditSectionComponent, 'edit_Section')
