import { GridColumn as Column } from '@progress/kendo-react-grid'
import { RasaContext } from 'context'
import React from 'react'
import { Button } from 'reactstrap'
import * as GenericRedux from '../../../generic/genericRedux'
import {PagingSource, RasaDataGrid} from '../../../generic/rasaDataGrid'
import './_styles.scss'

import { AddImageModal } from 'components/add-image/component'
import { AddSource } from 'components/icons/addSource'
import { AllSources } from 'components/icons/allsources'
import { UpgradeAnchorLink } from 'components/link'
import { Loading } from 'components/loading'
import * as GA from 'google-analytics'
import * as BillingPlan from 'shared_server_client/types/billing_plan'
import * as ContentPoolConstants from '../constants'
import { SourcesListComponent } from '../sources-list';
import './_styles.scss'

type Source = any

interface SourcesMainProps extends GenericRedux.DatasetContainerComponentProps<any> {
  sourcesChanged: any,
}
type SourcesProps = SourcesMainProps & GenericRedux.AllComponentPropsWithModal<any>

export class MobileSourcesListComponent extends SourcesListComponent {
  public static contextType = RasaContext;
  constructor(props: SourcesProps) {
    super(props);
  }

  public componentDidMount() {
    this.context.user.init().then(({activeCommunity}) => {
      if (activeCommunity.billingInfo && activeCommunity.billingInfo.currentPlan) {
        this.setState({
          boostSourcesCount: activeCommunity.billingInfo.usageStats.boostSourcesCount,
          loading: false,
          maxBoostSource: activeCommunity.billingInfo.usageStats.currentPlanMaxBoostSources,
          plan: activeCommunity.billingInfo.currentPlan,
        })
      }
    })
  }

  public render() {
    return (
      this.state.loading ? <Loading size="64" /> :
      <div className="source-grid">
        <div className="header-container">
            <div className="header-icon-and-text-container">
              <span className="header-icon">
                <AllSources svgwidth={35} svgheight={35}/>
              </span>
              <div><b>All Sources</b></div>
            </div>
            <this.mobileSourcesLimitReached/>
        </div>
        <RasaDataGrid<Source>
            entityName="communitySource"
            datasetName="communitySources"
            // props to pass along to underlying grid automatically
            editDefaultState={false}
            pagingSource={PagingSource.local}
            pageable={false}
            resizable={true}
            sortable={true}
            gridFilter={this.filterData}
            onRowClick={(e) => this.editSource(e.dataItem)}
            onDataReceived={this.dataReceived}
            data={[]}>
          <Column field="is_active" title="Allow" editor="boolean" cell={this.ToggleActiveCell}/>
          <Column field="name" title="Name" editable={false} cell={this.NameEditCell} width="200%"/>
          <Column field="subscription_type_id" title="Boost" cell={this.BoostedCell}/>
        </RasaDataGrid>
      </div>
    )
  }

  protected mobileSourcesLimitReached = () => {
    const maxSourceCount = BillingPlan.getCurrentPlanMaxSources(this.state.plan)
    if ( this.state.accountSourcesCount >= maxSourceCount ) {
      return (
      <div className="max-sources-container">
        <Button className="upcoming-sources-button disabled">
          <AddSource svgwidth={25} svgheight={25}/>
          <span className="button-text">
            Add Source
          </span>
        </Button>
      <div className="new-sources-wrapper">
              <div className="new-sources-wrapper-text">
              You've reached your limit of <b>{maxSourceCount} sources</b>
              </div>
              <UpgradeAnchorLink source={GA.UpgradeSource.AddSource}>
                Upgrade
              </UpgradeAnchorLink> to unlock more
              </div>
      </div> )
    }
    return (
      <Button className="upcoming-sources-button" onClick={
        () => this.props.push(ContentPoolConstants.CONTENT_ADD_SOURCE_PAGE)
      }>
        <AddSource svgwidth={25} svgheight={25}/>
        <span className="button-text">
          Add Source
        </span>
      </Button>
    )
  }
}

export const MobileContentPoolSourcesList = GenericRedux.registerNewComponentWithModals<any>(
  MobileSourcesListComponent, 'sources', [AddImageModal.key])
