import { GridCellProps } from '@progress/kendo-react-grid'
import { LinkOutIcon } from 'components/icons/link-out'
import React from 'react'

interface ArticleLinkProps  extends GridCellProps {
  height?: number,
  width?: number,
}

export class ArticleLink extends React.Component<ArticleLinkProps> {
    public render() {
      const url = this.props.dataItem.url ? this.props.dataItem.url : this.props.dataItem.identifier
      return (
        <td>
            {url
              ?
              <a href={url} target="_blank" rel="noopener">
                <LinkOutIcon width={ this.props.width || '20.15'} height={this.props.height || '20.15'}/>
              </a>
            : null
            }
        </td>
      )
    }
  }
