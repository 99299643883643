import { GridCellProps } from '@progress/kendo-react-grid'
import React from 'react'

interface Source {
  category: string
  identifier: string
  name: string
  sub_category: string
}

interface MobileSourceCellProps extends GridCellProps {
  dataItem: Source
  onClick: (source: Source) => void
}

export class MobileSourceCell extends React.Component<MobileSourceCellProps> {
  public render() {
    return (
      <td>
        <div className="source-name-container"
          onClick={() => this.props.onClick(this.props.dataItem)}>
          {this.props.dataItem[this.props.field]}
        </div>
      </td>
    )
  }
}
