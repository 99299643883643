import { GridCellProps } from '@progress/kendo-react-grid'
import { sizedUrl } from 'app/utils'
import classnames from 'classnames'
import { AttentionIcon } from 'components/icons/attention'
import React from 'react'
import { imageIsTooSmall } from '../utility/utils'

const HEIGHT: number = 100
const WIDTH: number = 100

export interface ImageCellProps extends GridCellProps {
  emailTemplate?: string,
  warningMessage?: string,
  onRowClick?: (item) => any
  skipResize?: boolean
}

export interface ImageCellState {
  imageIsTooSmall: boolean,
  imageAvailable: boolean,
}

export class ImageCell extends React.Component<ImageCellProps, ImageCellState> {

  constructor(props: ImageCellProps) {
    super(props)
    this.state = {
      imageAvailable: true,
      imageIsTooSmall: props.emailTemplate && props.dataItem.imageUrl && imageIsTooSmall(
        props.dataItem.image_height, props.dataItem.image_width, props.emailTemplate),
    }
  }

  public render() {
    const style: any = {
      height: HEIGHT,
    }
    if (!this.props.skipResize) {
      style.width = WIDTH
    }
    const imageUrl = sizedUrl(this.props.dataItem.hosted_image_url, HEIGHT, WIDTH)
    return (
      <td
        onClick={() => {
          if (this.props.onRowClick) {
            this.props.onRowClick(this.props)
          }
        }}
        className={classnames('image-cell', this.state.imageIsTooSmall ? 'small-image' : '')}>
        {imageUrl && this.state.imageAvailable && <img onError={this.noImage} src={imageUrl} style={style} alt={this.props.dataItem.image_alt_text}/>}
        {this.state.imageIsTooSmall
        ? <AttentionIcon svgwidth={25} title={this.props.warningMessage || ''} />
        : null
        }
      </td>
    )
  }
  private noImage = (e) => {
    this.setState({
      imageAvailable: false,
    })
  }
}
