import { orderBy } from '@progress/kendo-data-query'
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent} from '@progress/kendo-react-grid'
import Promise from 'bluebird'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import * as Flash from 'components/flash'
import { HeaderComponent } from 'components/header/component'
import { SearchIcon } from 'components/icons/searchicon'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { Dataset } from 'generic/dataset'
import { EditableDropdownCell, EditCellProps} from 'generic/editCell'
import * as GenericRedux from 'generic/genericRedux'
import * as GenericUtils from 'generic/utility'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import * as PapaParse from 'papaparse'
import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { arrayBufferToString, getSourceType } from 'shared/utils'
import * as Constants from '../constants'
import { ArticleLink, BlankCell, CategoriesCell, CopyToClipboardCell, TooltipCellHeader } from '../kendocells'
import { exportImportFieldMapping } from './constants'
import * as Types from './types'

export class ToConnectContentLibraryComponent extends Component<Types.ContentLibraryProps, Types.ContentLibraryState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: Types.ContentLibraryProps) {
    super(props)
    this.state = {
      communityId: null,
      contentLibraryData: [],
      isAdminUser: false,
      isDirty: false,
      isLoading: true,
      searchTerm: '',
      skip: 0,
      sort: [],
      take: 25,
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.role),
      this.loadContentLibraryData(),
    ])
      .then(([activeCommunity, role, dataLoad]) => {
        this.setState({
          communityId: activeCommunity.communityId,
          isAdminUser: role === Roles.super_admin ? true : false,
          isLoading: false,
        })
      })
  }

  public render() {
    return (
      <div className="content-library-wrapper">
        <HeaderComponent
          title={'Content Library'}
          subTitle={'Browse our extensive library to add content'}
        />
        {this.state.isLoading ? <Loading size="64"/>
        :
        <div>
          <div className="search-bar-and-add">
            <div className="search-bar">
              <label>
              <SearchIcon/>
              <input type="text"
                  value={this.state.searchTerm}
                  placeholder="Search..."
                  onChange={(e) => this.setSearch(e.target.value)}/>
              </label>
            </div>
            {this.state.isAdminUser && this.renderAdminSections()}
          </div>
          <div className="template-link-container">
            <a target="_blank"
              href={Constants.BULK_SOURCES_IMPORT_TEMPLATE_LOCATION}
              rel="noopener">Download Bulk Template
            </a>
          </div>

          <Grid
            skip={this.state.skip}
            take={this.state.take}
            total={this.data().length}
            onPageChange={(e: GridPageChangeEvent) => this.page(e)}
            sortable={{
              allowUnsort: true,
            }}
            sort={this.state.sort}
            onSortChange={(e: GridSortChangeEvent) => this.sort(e)}
            pageable={true}
            pageSize={25}
            data={this.dataToDisplay()}>
            <Column field="full_category" title="Category" cell={CategoriesCell} width={450}/>
            <Column field="name" title="Source Name" width={250}/>
            <Column field="identifier" title="" headerCell={BlankCell} cell={ArticleLink} width={45}/>
            <Column field="identifier" title="URL"
              headerCell={this.RssCellTooltipCell} cell={CopyToClipboardCell}/>
            <Column cell={this.SourceDetailsCell} width={50}/>
          </Grid>
        </div>
        }
      </div>
    )
  }

  private renderAdminSections = () => {
    return <div className="add-buttons">
      <div>
        <Button value="ADD"
                onClick={() => this.props.push(this.sourceUrl())}>
          ADD A SOURCE
        </Button>
      </div>
      <Button className="action-button">
        <input type="file" accept=".csv" name="file" id="file" onChange={this.onFileUpload}/>
        <div className="input-flex">
          <label className="cursor" htmlFor="file">
            BULK ADD
          </label>
        </div>
      </Button>
      <Button className="action-button" onClick={this.csvExport}>
        EXPORT
      </Button>
    </div>
  }

  private data = (): any[] => {
    const sources = (this.state.contentLibraryData.length ? this.state.contentLibraryData : [])
    if (this.state.searchTerm.length < 3) {
      return sources
    } else {
      const searchTerm = this.state.searchTerm.toLowerCase()
      return sources.filter((s: any) => (s.category || '').toLowerCase().includes(searchTerm) ||
                                        (s.sub_category || '').toLowerCase().includes(searchTerm) ||
                                        (s.name || '').toLowerCase().includes(searchTerm) ||
                                        (s.identifier || '').toLowerCase().includes(searchTerm))
    }
  }

  private loadContentLibraryData = () => {
    return new Dataset().loadGlobalDataset('sources').then((sources) => {
      this.setState({contentLibraryData: sources[0].filter((s) => !!s.is_content_guide_visible)})
    })
  }

  private dataToDisplay = (): any[] => {
    const orderedData: any[] = orderBy(this.data(), this.state.sort)
    return orderedData.slice(this.state.skip, this.state.skip + this.state.take)
  }

  private sort = (e: GridSortChangeEvent) => {
    this.setState({
      sort: e.sort,
    })
  }

  private page = (e: GridPageChangeEvent) => {
    this.setState({
      skip: e.page.skip,
      take: e.page.take,
    })
  }

  private setSearch = (s: string): void => {
    this.setState({
      searchTerm: s,
    })
  }

  private RssCellTooltipCell = (props: any) => {
    const tooltipText = 'Copy the URL below and paste in \'Add Source\''
    return <TooltipCellHeader {...props} tooltip={tooltipText}/>
  }

  private SourceDetailsCell = (props: EditCellProps) => {
    return this.state.isAdminUser
    ? <EditableDropdownCell {...props}
      canHide={true}
      canEdit={true}
      onEdit={this.editSource}
      onHide={this.onHide}/>
    : <BlankCell/>
  }

  private sourceUrl = (s: Types.Source = null) => {
    return s ? `${DashboardMenuOption.adminContentLibrary}/source?id=${s.id}` : `${DashboardMenuOption.adminContentLibrary}/source`
  }

  private editSource = (s: Types.Source) => this.props.push(this.sourceUrl(s))

  private csvExport = () => {
    let csvHeaders = ''
    for (const key in exportImportFieldMapping) {
      if (exportImportFieldMapping[key]) {
        csvHeaders += exportImportFieldMapping[key] + ','
      }
    }
    csvHeaders = csvHeaders.slice(0, -1) // remove last comma
    csvHeaders += '\n'
    const csvData = this.data().map((x) => {
      let currentRowData = ''
      for (const key in exportImportFieldMapping) {
        if (x[key] === null) {
          currentRowData += ','
        } else {
          currentRowData += x[key] + ','
        }
      }
      currentRowData = currentRowData.slice(0, -1) // remove last comma
      return currentRowData
    }).join('\n')
    GenericUtils.downloadTextFile(csvHeaders + csvData, 'csv')
  }

  private importColumnIndexesByName = (headers: string[]): any => {
    const lowerCaseHeaders: string[] = headers.map((h) => h.toLowerCase())
    const result = {}
    for (const key in exportImportFieldMapping) {
      if (lowerCaseHeaders.includes(exportImportFieldMapping[key].toLowerCase())) {
        result[key] =  lowerCaseHeaders.indexOf(exportImportFieldMapping[key].toLowerCase())
      } else {
        result[key] = -1
      }
    }
    return result
  }

  private onFileUpload = (event) => {
    const selectedFile =  event.target.files[0]
    const reader = new FileReader()
    reader.onload = (e: any) => {
      const data = arrayBufferToString(e.target.result)
      if (!isNil(data) && !isEmpty(data)) {
        const parseResult =  PapaParse.parse(data)
        // TODO: transform parseResult array or arrays into array of objects with headers as properties
        if (this.validateHeaders(parseResult)) {
          const headers = [...parseResult.data[0]]
          const importColumnIndexesByName = this.importColumnIndexesByName(headers)
          const records = parseResult.data.slice(1)
          return Promise.map(records, (r) => {
            if (this.validateRecord(r)) {
              return this.context.entityMetadata
                .getEntityObject('source')
                .then((entity) => {
                  entity.setRecordId(this.state.communityId, null)
                  const type = getSourceType(r[importColumnIndexesByName.type])
                  entity.data.id = null
                  entity.data.name = r[importColumnIndexesByName.name]
                  entity.data.identifier = type === 'Facebook' ? null : r[importColumnIndexesByName.identifier]
                  entity.data.category = r[importColumnIndexesByName.category]
                  entity.data.sub_category = r[importColumnIndexesByName.sub_category]
                  entity.data.type = type
                  entity.data.options = type === 'Facebook'
                    ? JSON.stringify({url: r[importColumnIndexesByName.identifier]}) : null
                  return entity.save()
                })
            }
          }).then(() => {
            this.loadContentLibraryData()
            this.context.store.dispatch(Flash.showFlashMessage(Constants.SUCCESSFUL_UPLOAD))
          })
        } else {
          this.context.store.dispatch(Flash.showFlashError(Constants.INVALID_HEADERS))
        }
      } else {
        this.context.store.dispatch(Flash.showFlashError(Constants.EMPTY_FILE))
      }
    }
    reader.readAsArrayBuffer(selectedFile)
  }

  private validateHeaders = (parseResult): boolean => {
    if (parseResult.data && parseResult.data.length) {
      const headers = [...parseResult.data[0]]
      for (const key in exportImportFieldMapping) {
        if (!headers.includes(exportImportFieldMapping[key])) {
          return false
        }
      }
      return true
    }
    return false
  }

  private validateRecord = (record): boolean => {
    return !record.slice(0, 4).includes('')
  }

  private onHide = (source): void => {
    return this.context.entityMetadata
      .getEntityObject('source', this.state.communityId, source.id)
      .then((entity) => {
        entity.data.id = source.id
        entity.data.name = source.name
        entity.data.identifier = source.identifier
        entity.data.category = source.category
        entity.data.sub_category = source.sub_category
        entity.data.type = source.type
        entity.data.options = source.options
        entity.data.is_content_guide_visible = false
        return entity.save()
      })
      .then(() => {
        this.context.store.dispatch(Flash.showFlashMessage(Constants.SUCCESSFUL_DELETE))
        this.loadContentLibraryData()
      })
      .catch((err) => {
        this.context.store.dispatch(Flash.showFlashError(Constants.UNSUCCESSFUL_DELETE))
        // eslint-disable-next-line no-console
        console.log('error: ', err)
      })
  }

}

export const ContentLibraryComponent = GenericRedux.registerNewDatasetContainerComponent<Types.Source>(
  ToConnectContentLibraryComponent, 'sources')
