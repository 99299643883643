import { GridCellProps } from '@progress/kendo-react-grid'
import { LighteningFull } from 'components/icons/lightening1'
import { LighteningEmpty } from 'components/icons/lightening3'
import React from 'react'

import {SubscriptionTypes} from 'shared_server_client/constants'

interface PreferredSourceCellProps extends GridCellProps {
  boostSourcesCount: number,
  maxBoostSource: number,
  onToggle: any,
}
export class PreferredSourceCell extends React.Component<PreferredSourceCellProps> {
  constructor(p: any) {
    super(p)
  }

  public render() {
    return <td>
      {!this.props.dataItem.is_archived &&
      <div onClick={(e) => this.toggle(e)} className="toggle-active">
        {this.isLead() ?
            <button className="toggle-button" style={{background: 'none', border: 'none', outline: 'none'}}>
              <LighteningFull/></button>
            :
            <button disabled={!this.canBoostSource()} className="toggle-button" style={{background: 'none', border: 'none', outline: 'none'}}>
              <LighteningEmpty/></button>
            }
        </div>}
    </td>
  }

  private isLead = (): boolean => {
    return this.props.dataItem.subscription_type_id === SubscriptionTypes.lead
  }

  private canBoostSource = (): boolean => {
    return this.isLead() || this.props.maxBoostSource === -1 || this.props.maxBoostSource === 1 ||
      this.props.maxBoostSource > this.props.boostSourcesCount
  }

  private toggle = (e: any) => {
    if (this.canBoostSource()) {
      const newSubscriptionType = this.isLead() ? SubscriptionTypes.normal : SubscriptionTypes.lead
      if (this.props.onChange) {
        this.props.onChange({
          dataItem: this.props.dataItem,
          syntheticEvent: e,
          field: 'subscription_type_id',
          value: newSubscriptionType,
        })
      }
      this.props.onToggle(this.props, newSubscriptionType)
    }
  }
}
