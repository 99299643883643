import { GridCellProps } from '@progress/kendo-react-grid'
import { ToggleOff } from 'components/icons/toggleoff'
import { ToggleOn } from 'components/icons/toggleon'
import React from 'react'

export interface ToggleAssignmentCellProps extends GridCellProps {
  onToggle: any
}

export class ToggleAssignmentCell extends React.Component<ToggleAssignmentCellProps> {
  constructor(props: ToggleAssignmentCellProps) {
    super(props)
  }

  public render() {
    return (
      <td >
        {!this.props.dataItem.is_archived &&
        <div onClick={(e) => this.toggle(e)} className="toggle-active">
          <button className="toggle-button" style={{background: 'none', border: 'none', outline: 'none'}}>
            {this.props.dataItem.is_section_assigned ? <ToggleOn/> : <ToggleOff/>}
          </button>
        </div>}
      </td>
    )
  }

  private toggle = (e: any) => {
    const isAssign: boolean = !this.props.dataItem.is_section_assigned
    this.props.onChange({
      dataItem: this.props.dataItem,
      syntheticEvent: e,
      field: 'is_section_assigned',
      value: isAssign,
    })
    this.props.onToggle(this.props.dataItem.id, isAssign)
  }
}
