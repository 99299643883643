import isNil from 'lodash/isNil'
import React from 'react'
import { LighteningFull } from '../../../components/icons/lightening1'
import { LighteningActive } from '../../../components/icons/lightening2'
import { LighteningEmpty } from '../../../components/icons/lightening3'

import { ChangeFunction } from '../types'

export interface BoostProps {
  boostLevel: number,
  onChange: ChangeFunction
}

interface BoostState {
  boostLevel: number,
}

export class BoostArticle extends React.Component<BoostProps, BoostState> {
  constructor(p: any) {
    super(p)
    this.state = {
      boostLevel: this.props.boostLevel,
    }
  }

  public render() {
    return <div onClick={(e) => this.boosted()} className="toggle-active boost-cell">
        {this.state.boostLevel === 1 ?
        <button className="toggle-button" style={{background: 'none', border: 'none', outline: 'none'}}>
          <LighteningFull/></button>
        : this.state.boostLevel === 2 ?
        <button className="toggle-button" style={{background: 'none', border: 'none', outline: 'none'}}>
          <LighteningActive /></button>
        :
        <button className="toggle-button" style={{background: 'none', border: 'none', outline: 'none'}}>
          <LighteningEmpty/></button>
        }
      </div>
  }

  private boosted = () => {
    const newBoostLevel: number = (isNil(this.state.boostLevel) || this.state.boostLevel === 0)
      ? 1 : this.state.boostLevel === 1 ? 2 : 0
    this.setState({
      boostLevel: newBoostLevel,
    })
    this.props.onChange({
      value: newBoostLevel,
    })
  }
}
