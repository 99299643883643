import React from 'react';

export interface HeaderTooltipProps {
  onClick: any,
  title: string,
  tooltip: string,
  className?: string,
}

export class TooltipCellHeader extends React.Component<HeaderTooltipProps> {
  constructor(p: HeaderTooltipProps) {
    super(p)
  }
  public render() {
      return (
        <a className={`k-link grid-tooltip ${this.props.className}`} onClick={this.props.onClick}>
          <span title={this.props.tooltip}>{this.props.title}</span>
          {this.props.children}
        </a>
      )
  }
}
