import { LinkedInIcon } from 'components/icons/linkedin'
import { preventDefault, preventDefaultThen } from 'components/link'
import * as Loading from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { isEmpty } from 'lodash'
import React from 'react'
import { Button } from 'reactstrap'
import * as Redux from 'redux'
import { SourceTypes } from 'shared_server_client/constants'
import { LinkedInOrganization, LinkedInPost } from 'shared_server_client/types/linkedin'
import { injectReducer } from 'store/index'
import { CONTENT_ADD_SOURCE_PAGE, DEFAULT_ARTICLE_DESCRIPTION_CAP } from '../constants'
import { Article } from '../utility/components'
import { isValidSourceName, postAddSource } from '../utility/utils'
import './_styles.scss'
import * as Constants from './constants'

interface Source {
  community_id?: string,
  identifier: string,
  name: string,
  options: string,
  type: string,
}

type AddSourceProps = GenericRedux.AllComponentProps<Source>

interface AddSourceState {
  activeCommunity: any,
  existingSources: any[],
  hasLinkedInSource: boolean,
  inputOrganization: string,
  inputOrganizationFound: boolean,
  errorMessage: string,
  isComponentLoading: boolean,
  name: string,
  organizationFeeds: LinkedInPost[], // will set this up when we find the posts from the selected organization
  organizationFeedsFound: boolean,
  selectedOrganization: LinkedInOrganization, // will set this up when we find the input organization
}

class ContentPoolAddLinkedInSourceComponent extends RasaReactComponent<AddSourceProps, AddSourceState> {
  public static contextType = RasaContext;
  constructor(props: AddSourceProps) {
    super(props, 'communitySource')

    this.state = {
      activeCommunity: null,
      existingSources: [],
      hasLinkedInSource: false,
      inputOrganization: '',
      inputOrganizationFound: true,
      errorMessage: '',
      isComponentLoading: true,
      isDirty: false,
      isLoading: true,
      isSaving: false,
      name: '',
      organizationFeeds: [],
      organizationFeedsFound: true,
      selectedOrganization: null,

    }
  }

  public componentDidMount = () => {
    this.context.user.init().then(({ person, activeCommunity }) => {
      this.setState({ activeCommunity })
      Promise.all([
        new Dataset().loadCommunityDataset('communitySources', activeCommunity.communityId),
      ])
        .then(([sourcesResponse]) => {
          const existingSources = sourcesResponse[0] || []
          const hasLinkedInSource = existingSources.filter((s) => {
            return s.source_type === SourceTypes.linkedIn && s.is_active
          })
          if (hasLinkedInSource.length > 0) {
            this.setState({ hasLinkedInSource: true })
          } else {
            this.setState({
              existingSources,
              hasLinkedInSource: false,
            })
          }
          this.setState({
            isComponentLoading: false,
            isLoading: false,
          })
        })
        .catch((ex) => {
          this.setState({ isComponentLoading: false })
          // hanlde error in UI
          // eslint-disable-next-line no-console
          console.log(ex)
        })
    })
  }

  public render = () => {
    return this.state.isComponentLoading ? <Loading.Loading size={Loading.SIZES.LARGE} /> :
      this.state.hasLinkedInSource
        ? <div className={`${this.context.store.getState().config.isMobile ? 'add-linkedin-wrapper-mobile' : 'add-linkedin-wrapper'}`}>
          <div className="source-exists" >You already have {SourceTypes.linkedIn} as a source!</div>
          <Button className="change" onClick={() => this.changeSelection()}>Ok</Button>
        </div>
        :
        <div className={`${this.context.store.getState().config.isMobile ? 'add-linkedin-wrapper-mobile' : 'add-linkedin-wrapper'}`}>
          {isEmpty(this.state.selectedOrganization) &&
            <div className="linkedin sources">
              <div className="image">
                <LinkedInIcon svgwidth={16.48} svgheight={16.48} />
              </div>
              <div className="words">
                <h2>{SourceTypes.linkedIn}</h2>
                <p>What's the Organization ID / Domain / URL?</p>
                <div className="input-area">
                  <form onSubmit={preventDefaultThen(() => this.searchOrganization())}>
                    <input autoFocus disabled={this.state.isLoading} className="field-textbox"
                      placeholder="Organization ID | Domain | URL"
                      value={this.state.inputOrganization}
                      onChange={(e: any) => this.setState({ inputOrganization: e.target.value })} />
                    <Button disabled={this.state.inputOrganization.trim() === '' || this.state.isLoading}>Next</Button>
                  </form>
                </div>
                {!this.state.inputOrganizationFound && <span className="warning">
                  {this.state.errorMessage}</span>}
              </div>
            </div>
          }
          {!isEmpty(this.state.selectedOrganization) &&
            <div className={`${this.context.store.getState().config.isMobile ? 'add-linkedin-wrapper-mobile' : 'add-linkedin-wrapper'}`}>
              <h2 className="sources-heading">Sources</h2>
              <div className="section-header-text">Add {SourceTypes.linkedIn} Organization Source</div>
              <p className="section-detail-text">We will pull articles from your Organnization posts.</p>
              <div className="linkedin confirm sources">
                <div className="image">
                  <LinkedInIcon svgwidth={16.48} svgheight={16.48} />
                </div>
                <div className="words">
                  <h2>{SourceTypes.linkedIn}</h2>
                  <p>
                    <strong className="feed-result-text">
                      We connected to your Organization {this.state.selectedOrganization.name}.</strong>
                  </p>
                  <p>Specify how you would like this source to be named in your newsletter and hit confirm.</p>
                  {!isValidSourceName(this.state.existingSources, this.state.name) &&
                    <div className="invalid-source-name-container">You already have a source with this name</div>}
                  <div className="input-area">
                    <form onSubmit={preventDefault()}>
                      <input autoFocus className="field-textbox"
                        value={this.state.name}
                        onChange={(e: any) => this.setState({ name: e.target.value })}
                      />
                      <Button
                        onClick={() => this.addSource({
                          community_id: this.state.activeCommunity.communityId,
                          identifier: `linkedin-${this.state.activeCommunity.communityId}`,
                          name: this.state.name,
                          options: JSON.stringify({ organization_id: this.state.selectedOrganization.id}),
                          type: SourceTypes.linkedIn,
                        })
                        }
                        disabled={this.state.isComponentLoading ||
                          !isValidSourceName(this.state.existingSources, this.state.name)}>Confirm</Button>
                      <Button className="change" onClick={() => this.changeSelection()}>Cancel</Button>
                    </form>
                  </div>
                </div>
              </div>
              <div className={`${this.context.store.getState().config.isMobile
                ? 'recent-articles-mobile' : 'recent-articles'}`}>
                <h4>Recent Articles</h4>
                {this.state.organizationFeeds.slice(0, 5).map((feed: any, i: number) =>
                  <Article key={i} article={feed} descriptionCap={DEFAULT_ARTICLE_DESCRIPTION_CAP}/>)}
                {!this.state.organizationFeedsFound && <span className="warning">We could not find any article.</span>}
              </div>
            </div>
          }
        </div>
  }

  private searchOrganization() {
    this.setState({ isLoading: true, errorMessage: '' }, () =>
      AjaxWrapper.ajax(AjaxWrapper.getServerUrl() + `/linkedin/organization/search/${this.state.activeCommunity.communityId}?q=${this.state.inputOrganization}`,
        HttpMethod.GET,
        {},
      )
        .then((linkedInOrganization) => {
          const isOrganizationFound = !isEmpty(linkedInOrganization)
          return this.setState({
            inputOrganizationFound: isOrganizationFound,
            isLoading: false,
            errorMessage: !isOrganizationFound ? 'We could not locate the Organization' : '',
            selectedOrganization: linkedInOrganization,
          }, () => {
            if (this.state.inputOrganizationFound) {
              return this.getShares()
            }
          })
        })
        .catch((ex) => {
          const errMessage = ex.response.message ? ex.response.message : 'Something went wrong.'

          this.setState({
            isLoading: false,
            isComponentLoading: false,
            inputOrganizationFound: false,
            errorMessage: errMessage,
          })
        }),
    )
  }

  private getShares() {
    return this.setState({ isComponentLoading: true }, () =>
      AjaxWrapper.ajax(AjaxWrapper.getServerUrl() + `/linkedin/shares/${this.state.activeCommunity.communityId}?organization_id=${this.state.selectedOrganization.id}`,
        HttpMethod.GET,
        {},
      )
        .then((shares) => {
          return this.setState({
            isComponentLoading: false,
            organizationFeeds: shares.map((s) => {
              const article: any = {
                url: s.url,
              }
              article.description = s.description
              article.image = s.image
              article.title = s.title
              return article
            }),
            organizationFeedsFound: shares.length > 0,
          })
        }),
    )
  }

  private changeSelection() {
    this.setState({
      inputOrganization: '',
      inputOrganizationFound: true,
      organizationFeeds: [],
      organizationFeedsFound: true,
      selectedOrganization: null,
    })
    this.props.push(CONTENT_ADD_SOURCE_PAGE)
  }

  private addSource = (source: Source) => {
    this.setState({ communityId: this.state.activeCommunity.communityId }, () => {
      this.props.propertiesChanged({
        ...source,
        community_id: this.state.activeCommunity.communityId,
      })
      this.saveRecord(null).then(() => {
        postAddSource(this.context, 'linkedin', source.identifier, this.props.push)
      })
    })
  }
}

export const ContentPoolAddLinkedInSource = GenericRedux.createConnect(ContentPoolAddLinkedInSourceComponent, Constants.REDUX_STORE_HOME)
injectReducer(
  Constants.REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(Constants.REDUX_STORE_HOME, {}),
  }),
)
