import { GridCellProps } from '@progress/kendo-react-grid'
import isNil from 'lodash/isNil'
import React from 'react'
import { NORMAL_BOOSTED_BOOST_LEVEL, SUPER_BOOSTED_BOOST_LEVEL } from 'shared_server_client/constants'
import { LighteningFull } from '../../../components/icons/lightening1'
import { LighteningActive } from '../../../components/icons/lightening2'
import { LighteningEmpty } from '../../../components/icons/lightening3'

export type OnBoost = (dataItem: any, boostLevel: any) => void

interface BoostCellProps extends GridCellProps {
  disabled?: boolean,
  onBoost?: OnBoost,
}
export class BoostCell extends React.Component<BoostCellProps> {
  constructor(p: BoostCellProps) {
    super(p)
  }

  public render() {
    return <td>
      {!this.props.dataItem.is_archived &&
      <div onClick={(e) => this.boost(e)} className="toggle-active boost-cell">
        {this.props.dataItem.boost_level === NORMAL_BOOSTED_BOOST_LEVEL ?
        <button disabled={this.props.disabled} className="toggle-button"
          style={{background: 'none', border: 'none', outline: 'none'}}>
          <LighteningFull/></button>
        : this.props.dataItem.boost_level === SUPER_BOOSTED_BOOST_LEVEL ?
        <button disabled={this.props.disabled} className="toggle-button"
          style={{background: 'none', border: 'none', outline: 'none'}}>
          <LighteningActive/></button>
        :
        <button disabled={this.props.disabled} className="toggle-button"
          style={{background: 'none', border: 'none', outline: 'none'}}>
          <LighteningEmpty/></button>
        }
      </div>}
    </td>
  }

  private boost = (e: any) => {
    // TODO: Add support for the "SuperBoost - category 2"
    // Needs server work: when we toggle one on, the others need to be turned off.
    if (this.props.disabled) { return }
    const newBoost: number =
      (isNil(this.props.dataItem.boost_level) || this.props.dataItem.boost_level === 0) ? NORMAL_BOOSTED_BOOST_LEVEL :
        this.props.dataItem.boost_level === NORMAL_BOOSTED_BOOST_LEVEL ? SUPER_BOOSTED_BOOST_LEVEL : 0
    if (this.props.onChange) {
      this.props.onChange({
        dataItem: this.props.dataItem,
        syntheticEvent: e,
        field: 'boost_level',
        value: newBoost,
      })
    }
    if ( this.props.onBoost ) {
      this.props.onBoost(this.props.dataItem, newBoost)
    }
  }
}
