import React from 'react'
import { ToggleOff } from '../../../components/icons/toggleoff'
import { ToggleOn } from '../../../components/icons/toggleon'

import { ChangeFunction } from '../types'
export interface ActiveProps {
  isActive: any,
  onChange: ChangeFunction
}

interface ActiveState {
  isActive: boolean,
}

export class ActiveArticle extends React.Component<ActiveProps, ActiveState> {
  constructor(props: ActiveProps) {
    super(props)
    this.state = {
      isActive: this.props.isActive,
    }
  }

  public render() {
    return <div onClick={() => this.toggle()} className="toggle-active">
          <button className="toggle-button" style={{background: 'none', border: 'none', outline: 'none'}}>
            {this.state.isActive ? <ToggleOn/> : <ToggleOff/>}
          </button>
        </div>
  }

  private toggle = () => {
    const newIsActive: boolean = !this.state.isActive
    this.setState({
      isActive: newIsActive,
    })
    if (this.props.onChange) {
      this.props.onChange({
        value: newIsActive,
      })
    }
  }
}
