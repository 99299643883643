import { sizedUrl } from 'app/utils'
import React from 'react';
import { Col, Row } from 'reactstrap'

export interface ArticleProps {
  articleImage: any,
  articleTitle: any,
  description: any,
  articleUrl: string,
}
const HEIGHT: number = 50
const WIDTH: number = 50
const IMAGE_STYLE: any = {
  height: HEIGHT,
  width: WIDTH,
}

export class BodyArticle extends React.Component<ArticleProps> {
  public render() {
    const imageUrl = sizedUrl(this.props.articleImage, HEIGHT, WIDTH)
    const imageWidth = imageUrl ? 2 : 0
    const descriptionWidth = 12 - imageWidth
    return (
      <div className="mobile-title-words">
        <Row>
          <b className="mobile-article-title">
            {this.props.articleUrl
              ?
              <a className="mobile-article-title-text"
                href={this.props.articleUrl} target="_blank" rel="noopener">
                <span>{this.props.articleTitle}</span>
              </a>
            :
              <span>{this.props.articleTitle}</span>}
          </b>
        </Row>
        <Row>
          <Col xs={descriptionWidth} className="article-description">
            <p>
              {this.props.description}
            </p>
          </Col>
          {imageUrl &&
          <Col xs={imageWidth} className="article-image">
            <img src={imageUrl} style={IMAGE_STYLE}/>
          </Col>}
        </Row>
      </div>
    )
  }
}
