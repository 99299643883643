import { orderBy } from '@progress/kendo-data-query'
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent} from '@progress/kendo-react-grid'
import { showAppCue } from 'components/app-cues/actions'
import { APP_CUE_TYPE } from 'components/app-cues/constants'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { Books } from 'components/icons/books'
import { SearchIcon } from 'components/icons/searchicon'
import { UpgradeAnchorLink } from 'components/link'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as GA from 'google-analytics'
import React from 'react'
import * as Redux from 'redux'
import { getActivesSourcesCount } from 'shared/utils'
import * as BillingPlan from 'shared_server_client/types/billing_plan'
import { injectReducer } from 'store/index'
import { CategoriesCell, MobileSourceCell } from '../kendocells'
import * as Types from '../library/types'

export class MobileContentLibraryComponent
  extends RasaReactComponent<Types.ContentLibraryProps, Types.ContentLibraryState> {
  public static contextType = RasaContext
  private contentLibraryData = []

  constructor(props: Types.ContentLibraryProps) {
    super(props, 'communitySource')
    this.state = {
    activeSourcesCount: null,
    communityId: null,
    isAdminUser: false,
    isDirty: false,
    isLoading: true,
    isSaving: false,
    maxAllowedSources: null,
    searchTerm: '',
    skip: 0,
    sort: [],
    take: 10,
    }
  }

  public componentDidMount = () => {
    Promise.all([
      this.context.user.init(),
      this.loadContentLibraryData(),
    ])
      .then(([user, dataLoad]) => {
        const maxAllowedSources = BillingPlan.getCurrentPlanMaxSources(
          user.activeCommunity.billingInfo.currentPlan,
        )
        this.loadRecord(user.activeCommunity.communityId, null).then(() =>
          this.setState({
            communityId: user.activeCommunity.communityId,
            isLoading: false,
            activeSourcesCount: getActivesSourcesCount(user),
            maxAllowedSources,
          }),
        )
      })
  }

  public render() {
    return (
      <div className="mobile-content-library-wrapper">
        <div className="header-wrapper">
          <div className="icon-container">
            <Books svgwidth={50} svgheight={50}/>
          </div>
          <div className="header-title-and-text">
            <h4 className="header-title">Content Library</h4>
            <p>Browse our extensive library to add content</p>
          </div>
        </div>
        <div className="search-bar-and-add">
          <div className="search-bar">
            <label>
            <SearchIcon/>
            <input type="text"
                value={this.state.searchTerm}
                placeholder="Search..."
                onChange={(e) => this.setSearch(e.target.value)}/>
            </label>
          </div>
        </div>
        {this.state.isLoading ?
        <Loading size="32"/> :
        <div>
          {this.maxSourcesReached()
          ? <div className="helper-header"> You've reached your limit of {this.state.maxAllowedSources} sources.
            <br/>
            <UpgradeAnchorLink source={GA.UpgradeSource.AddSource}>
                Upgrade
            </UpgradeAnchorLink> to unlock more
            </div>
          : <p className="helper-header">Click on a Source Name to add the source</p>
          }
          <Grid
            skip={this.state.skip}
            take={this.state.take}
            total={this.data().length}
            onPageChange={(e: GridPageChangeEvent) => this.page(e)}
            sortable={{
              allowUnsort: true,
            }}
            sort={this.state.sort}
            onSortChange={(e: GridSortChangeEvent) => this.sort(e)}
            pageable={true}
            pageSize={10}
            data={this.dataToDisplay()}>
            <Column field="category" title="Category" cell={CategoriesCell}/>
            <Column field="name" title="Source Name" cell={this.SourceNameCell}/>
          </Grid>
        </div>}
      </div>
    )
  }

  private data = (): any[] => {
    const sources = (this.contentLibraryData.length ? this.contentLibraryData : [])
    if (this.state.searchTerm.length < 3) {
      return sources
    } else {
      const searchTerm = this.state.searchTerm.toLowerCase()
      return sources.filter((s: any) => (s.category || '').toLowerCase().includes(searchTerm) ||
                                        (s.sub_category || '').toLowerCase().includes(searchTerm) ||
                                        (s.name || '').toLowerCase().includes(searchTerm) ||
                                        (s.identifier || '').toLowerCase().includes(searchTerm))
    }
  }

  private loadContentLibraryData = () => {
    return new Dataset().loadGlobalDataset('sources').then((sources) => {
      this.contentLibraryData = sources[0]
    })
  }

  private dataToDisplay = (): any[] => {
    const orderedData: any[] = orderBy(this.data(), this.state.sort)
    return orderedData.slice(this.state.skip, this.state.skip + this.state.take)
  }

  private sort = (e: GridSortChangeEvent) => {
    this.setState({
      sort: e.sort,
    })
  }

  private page = (e: GridPageChangeEvent) => {
    this.setState({
      skip: e.page.skip,
      take: e.page.take,
    })
  }

  private setSearch = (s: string): void => {
    this.setState({
      searchTerm: s,
    })
  }

  private addSource = (s: Types.Source): void => {
    if (!this.maxSourcesReached()) {
      this.props.propertiesChanged({
        community_id: this.state.communityId,
        identifier: s.identifier,
        name: s.name,
        options: s.options,
        type: s.type,
      })
      this.saveRecord(null).then(() => {
        // eslint-disable-next-line no-console
        console.log('Calling Source Added Appcues')
        this.context.store.dispatch(showAppCue(APP_CUE_TYPE.SOURCE_ADDED))
        this.props.push(`${DashboardMenuOption.content}/sources`)
      })
    }
  }

  private SourceNameCell = (props: any) => {
    return <MobileSourceCell {...props}
      onClick={this.addSource}/>
  }

  private maxSourcesReached = (): boolean => this.state.activeSourcesCount >= this.state.maxAllowedSources

}

export const MobileContentLibrary = GenericRedux.createConnect(
  MobileContentLibraryComponent, 'contentLibrary')

injectReducer('contentLibrary',
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer('contentLibrary', {}),
  }),
)
