import { GridCell } from '@progress/kendo-react-grid'
import * as Constants from 'components/analytics/constants'
import { isEmpty } from 'lodash'
import React from 'react'

export class FailureMsgCell extends GridCell {
    public render() {
      return (
        <td className="source-words">
          <div>
            <b>{this.getErrorMsgType(this.props.dataItem.last_failure_reason)}</b>
          </div>
        </td>
      )
    }
  private getErrorMsgType = (errorMsg: string) => {
    if (isEmpty(errorMsg)) {
      return ''
    }
    errorMsg = (errorMsg).toLowerCase()
    for (const reasonObject of Constants.SOURCE_FAILURE_REASONS) {
      for (const r of reasonObject.reasons) {
        if (errorMsg.indexOf(r) !== -1) {
          return reasonObject.type
        }
      }
    }
    return 'Unknown'
    }
  }
