import { GridCellProps } from '@progress/kendo-react-grid'
import React from 'react'

interface Source {
  category: string
  identifier: string
  name: string
  sub_category: string
}

interface CategoriesCellProps extends GridCellProps {
  dataItem: Source
}

export class CategoriesCell extends React.Component<CategoriesCellProps> {
  public render() {
    const categories = this.props.dataItem[this.props.field]
    const categoriesArray = (categories ? categories.split('|') : []).map((s) => s.trim())
    const uniqueArray = categoriesArray.filter((a, index, array) => array.indexOf(a) === index)
    return (
      <td>
        <div className="categories-container">
          {uniqueArray.map((category, i) => {
            return <div key={i} className="category">{category.replace(',', ' | ')}</div>
          })}
        </div>
      </td>
    )
  }
}
