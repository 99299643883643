import { GridColumn as Column } from '@progress/kendo-react-grid'
import { ProgressBar } from '@progress/kendo-react-progressbars'
import { AddImageModal } from 'components/add-image/component'
import { ImageOptions } from 'components/add-image/constants'
import * as Flash from 'components/flash'
import { HeaderComponent } from 'components/header/component'
import { SearchIcon } from 'components/icons/searchicon'
import { Loading } from 'components/loading'
import { UpgradeButton } from 'components/upgrade/button'
import { UPGRADE_BUTTON_COLOR } from 'components/upgrade/constants'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { EditableDropdownCell, EditCellProps} from 'generic/editCell'
import * as GenericRedux from 'generic/genericRedux'
import { PagingSource, RasaDataGrid } from 'generic/rasaDataGrid'
import { isTruthy, tryParseJSON } from 'generic/utility'
import * as GA from 'google-analytics'
import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { SourceTypes, SubscriptionTypes } from 'shared_server_client/constants'
import * as BillingPlan from 'shared_server_client/types/billing_plan'
import './_styles.scss'
import * as Constants from './constants'
import {
  ActiveCell,
  BlankCell,
  DateTimeCell,
  NameCell,
  PreferredSourceCell,
  SourceIdentifierCell,
} from './kendocells'
import { SectionTitleCell } from './kendocells/sectionTitleCell'
import './styles.css'

type Source = any
const HEIGHT: number = 100
const WIDTH: number = 100

interface SourcesState {
  accountSourcesCount: number,
  boostSourcesCount: number,
  canBoostSource: boolean,
  communityId?: string,
  isSuperUser: boolean,
  loading: boolean,
  maxBoostSource: number,
  plan?: BillingPlan.BillingPlan,
  search: string,
  sources: any[],
}

interface SourcesMainProps extends GenericRedux.DatasetContainerComponentProps<any> {
  sourcesChanged: any,
}
type SourcesProps = SourcesMainProps & GenericRedux.AllComponentPropsWithModal<any>

const IMAGE_URL = 'image_url'
const IMAGE_HEIGHT = 'image_height'
const IMAGE_WIDTH = 'image_width'

export class SourcesListComponent extends Component<SourcesProps, SourcesState> {
  public static contextType = RasaContext;
  private sharedStore: SharedStore
  constructor(props: SourcesProps) {
    super(props);
    this.state = {
      accountSourcesCount: 0,
      boostSourcesCount: 0,
      canBoostSource: false,
      isSuperUser: false,
      loading: true,
      maxBoostSource: 0,
      search: '',
      sources: [],
    }
    this.onBoostToggle = this.onBoostToggle.bind(this)
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.role),
    ]).then(([role]) => {
      this.setState({isSuperUser : role === Roles.super_admin})
    })

    this.context.user.init().then(({activeCommunity, person}) => {
      if (activeCommunity.billingInfo && activeCommunity.billingInfo.currentPlan) {
        this.setState({
          accountSourcesCount: activeCommunity.billingInfo.usageStats.sourcesCount,
          boostSourcesCount: activeCommunity.billingInfo.usageStats.boostSourcesCount,
          canBoostSource: activeCommunity.billingInfo.usageStats.canBoostSources,
          communityId: activeCommunity.communityId,
          loading: false,
          maxBoostSource: activeCommunity.billingInfo.usageStats.currentPlanMaxBoostSources,
          plan: activeCommunity.billingInfo.currentPlan,
        })
      }
    })
  }

  public render() {
    if (this.state.loading) {
      return (<Loading size="64" />)
    } else {
      return (
        <div className="source-grid">
          <div className="header-container">
            <HeaderComponent
              title={'SOURCES'}
              subTitle={'All Sources'}
              description={[
                'Sources that will be checked to bring in new content for your newsletter.',
                'Boost a source to have all of its content included in every newsletter.',
              ]}
            />
            <this.sourcesLimitReached/>
          </div>
          <div>
            <div className="search-bar">
              <label>
                <SearchIcon/>
                <input type="text"
                      value={this.state.search}
                      placeholder="Search..."
                      onChange={(e) => this.setSearch(e.target.value)}/>
              </label>
            </div>
          </div>
          <RasaDataGrid<Source>
              entityName="communitySource"
              datasetName="communitySources"
              // props to pass along to underlying grid automatically
              editDefaultState={false}
              pagingSource={PagingSource.local}
              pageable={false}
              resizable={true}
              sortable={true}
              gridFilter={this.filterData}
              gridTransform={this.transformData}
              onRowClick={(e) => this.editSource(e.dataItem)}
              onDataReceived={this.dataReceived}
              data={[]}>
            <Column field="is_active" title="Allow" editor="boolean" cell={this.ToggleActiveCell} width="80%"/>
            <Column field="source_type" title="Source" editable={false} width="80%"/>
            <Column field="hosted_image_url" title="Preview" cell={this.previewCell} width="150%"/>
            <Column field="name" title="Name" editable={false} cell={this.NameEditCell} width="300%" />
            <Column field="source_url" cell={SourceIdentifierCell} title="Source URL" editable={false} width="200%"/>
            <Column field="last_week_total" title="# Upcoming" editable={false} width="95%"/>
            <Column field="section_name" title="Section" editable={false} cell={this.SectionNameCell} width="95%"/>
            <Column field="last_success" title="Last Fetch" editable={false} cell={DateTimeCell} width="95%"/>
            <Column field="last_publish" title="Last Publish" editable={false} cell={this.lastPublishDate} width="95%"/>
            <Column field="created" title="Created" editable={false} cell={DateTimeCell} width="95%"/>
            <Column field="subscription_type_id" title="Boosted" cell={this.BoostedCell} width="95%"/>
            <Column cell={this.SourceEditCell} width="50%"/>
          </RasaDataGrid>
          <AddImageModal
            data={this.props.modals}
            closeModal={this.props.closeModal}
            saveModal={this.props.saveModal}
            updateModal={this.props.updateModal}
            saveText="Save"
            onSave={this.onAddImage}
            title="Add Image to Source"/>
        </div>
      )
    }
  }

  protected BoostedCell = (props: EditCellProps) => {
    return props.dataItem.source_type !== SourceTypes.native ?
      <PreferredSourceCell
        boostSourcesCount={this.state.boostSourcesCount}
        maxBoostSource={this.state.maxBoostSource}
        onToggle={this.onBoostToggle}
       {...props} /> : <BlankCell />
  }
  protected transformData = (a) => {
    const input = this.state.sources.filter((x) => x.id === a.id && this.filterData(x))[0]
    input.is_active = a.is_active
    if (input.is_archived !== a.is_archived) {
      input.is_archived = a.is_archived
      input.is_active = !isTruthy(input.is_archived) ? true : a.is_active
    }
    if (input.load_posts !== a.load_posts) {
      input.load_posts = a.load_posts
    }
    if (input.is_full_load !== a.is_full_load) {
      input.is_full_load = a.is_full_load
    }
    return input
  }
  protected onBoostToggle = (props: EditCellProps, newBoostValue) => {
    let sourcesList = this.state.sources.map((x) => x)
    if (this.state.maxBoostSource === 1) {
      sourcesList = sourcesList.map((x) => {
        return {
          ...x,
          subscription_type_id: SubscriptionTypes.normal,
        }
      })
    }
    sourcesList = sourcesList.map((x) => {
      return {
        ...x,
        subscription_type_id: props.dataItem.id === x.id ? newBoostValue : x.subscription_type_id,
      }
    })
    this.setState({
      sources: sourcesList,
      boostSourcesCount: newBoostValue === SubscriptionTypes.lead ? this.state.boostSourcesCount + 1 :
        this.state.boostSourcesCount - 1,
    })
  }
  protected ToggleActiveCell = (props: EditCellProps) => {
    return props.dataItem.source_type !== SourceTypes.native ?
      <ActiveCell {...props} onToggle={this.onActiveToggle} /> : <BlankCell />
  }

  protected SourceEditCell = (props: EditCellProps) => {
    return props.dataItem.source_type !== SourceTypes.native ? <EditableDropdownCell {...props}
      onEdit={this.editSource}
      onRefresh={this.refreshSource}
      canRefresh={isTruthy(props.dataItem.is_active) && props.dataItem.source_type !== SourceTypes.native}
      canArchive={true}
      canAddImage={this.state.isSuperUser}
      onAddImage={() => this.props.openModal(AddImageModal.key, props.dataItem)}
      canEdit={true}
      canRestore={true}
      canToggleFullLoad={this.state.isSuperUser}
      canToggleLoadPosts={this.state.isSuperUser}/> : <BlankCell />
  }

  protected NameEditCell = (props: EditCellProps) => {
    return <NameCell {...props} onClick={this.editSource} />
  }

  protected SectionNameCell = (props: EditCellProps) => {
    return <SectionTitleCell {...props} />
  }

  protected dataReceived = (event) => {
    if (event.data) {
      const includedSources = event.data.filter((r) => r.source_type !== SourceTypes.native)
      const manualSources = event.data.filter((r) => r.source_type === SourceTypes.native)
      const manualSource = manualSources.reduce((result, x) => {
        return {
          ...result,
          ...x,
          section_name: '',
          last_run: x.last_run ? new Date(Math.max.apply(null, [result.last_run, x.last_run])) : result.last_run,
          last_week_total: result.last_week_total + x.last_week_total,
          last_week_quality: result.last_week_total + x.last_week_quality,
        }
      }, {
        last_run: null,
        last_week_total: 0,
        last_week_quality: 0,
      })
      event.data = [manualSource].concat(includedSources)
      this.setState({
        sources: event.data,
      })
    }
  }

  protected filterData = (s: Source): boolean => {
    return this.state.search.length <= 3 || (
      (s.name && s.name.indexOf(this.state.search) >= 0) ||
      (s.source_url && s.source_url.indexOf(this.state.search) >= 0)
    )
  }

  protected setSearch = (s: string) => {
    this.setState({
      search: s,
    })
  }

  protected sourceUrl = (s: Source) => `/content/editsource?id=${s.id}`

  protected editSource = (s: Source) => !s.is_archived && this.props.push(this.sourceUrl(s))

  protected refreshSource = (s: Source) => {
    this.setState({loading: true}, () =>
      AjaxWrapper.ajax(AjaxWrapper.getServerUrl() + '/content/sources/refresh',
        HttpMethod.POST,
        s,
      )
      .then(() => {
        this.setState({
          loading: false,
        }, () => this.context.store.dispatch(Flash.showFlashMessage(Constants.SOURCE_REFRESHED)))
      })
      .catch((error) => this.setState({loading: false})),
    )
  }

  protected sourcesLimitReached = () => {
    const maxSourceCount = BillingPlan.getCurrentPlanMaxSources(this.state.plan)
    const isUnlimited = maxSourceCount === BillingPlan.UNLIMITED_VALUE || maxSourceCount === -1
    const maxLimitReached = !isUnlimited &&
      this.state.accountSourcesCount >= maxSourceCount
    return <div className="source-right-side">
          <div className="sources-button-container">
          {!isUnlimited ?
          <div className="div-progress-bar">
            <ProgressBar
              min={0}
              className={`progress-bar${maxLimitReached ? '-red' : ''}`}
              max={maxSourceCount}
              value={this.state.accountSourcesCount}
              labelVisible={false}/>
            <div className="progress-bar-label">
              <div>Sources used</div>
              <div>{this.state.accountSourcesCount}/{maxSourceCount}</div>
            </div>
          </div> : null}
          {maxLimitReached ?
          <div>
            <UpgradeButton color={UPGRADE_BUTTON_COLOR.WHITE} showIcon={true}
              source={GA.UpgradeSource.AddSource} text="Upgrade" />
          </div> :
          <div className="add-sources-button-text-section">
            <Button className="add-button" onClick={() => this.props.push(Constants.CONTENT_ADD_SOURCE_PAGE)}>
              <span className="button-text">
                Add New Content
              </span>
          </Button>
          </div>}
        </div>
        {maxLimitReached ?
        <div className="max-limit-description">
          You've reached your sources limit, please upgrade your plan to add new content.
        </div> : null}
      </div>
  }

  private lastPublishDate = (props: EditCellProps) => {
    const sourceOptions = tryParseJSON(props.dataItem.source_options) || {}
    if (sourceOptions.last_publish) {
      props.dataItem.last_publish = sourceOptions.last_publish
      return <DateTimeCell {...props}></DateTimeCell>
    }
    return <td></td>
  }

  private onAddImage = (s: any, options: ImageOptions) => {
    // Write save logic here
    this.context.entityMetadata.getEntityObject('communitySource').then(
      (entityObject: any ) => {
      entityObject.setRecordId(this.state.communityId, s.id)
      entityObject.data[IMAGE_URL] = options.imageUrl
      entityObject.data[IMAGE_HEIGHT] = options.imageWidth
      entityObject.data[IMAGE_WIDTH] = options.imageHeight
      entityObject.save()
      window.location.reload()
    })
    return Promise.resolve(null)
  }

  private previewCell = (props) => {
    const style: any = {
      height: HEIGHT,
      width : WIDTH,
    }
    return <td className={'image-cell'}>
     {props.dataItem.image_url ? <img src={props.dataItem.image_url} style={style}/> : null}
      </td>
  }

  private onActiveToggle = (props: EditCellProps, newActiveValue) => {
    this.sharedStore.refresh()
    this.setState({
      accountSourcesCount: newActiveValue ? this.state.accountSourcesCount + 1 : this.state.accountSourcesCount - 1,
    })
  }
}

export const ContentPoolSourcesList = GenericRedux.registerNewComponentWithModals<any>(
  SourcesListComponent, 'sources', [AddImageModal.key])
