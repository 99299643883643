import { GridCell } from '@progress/kendo-react-grid'
import * as React from 'react'

export class DateTimeCell extends GridCell {
  public render() {
    const options1 = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    }

    const options2 = {
      ...options1,
      hours: 'numeric',
      minutes: 'numeric',
    }
    const date = this.props.dataItem[this.props.field]
    return (
      <td className="datetime-cell">
      {
        date ? (
          new Date(date).toLocaleDateString('en-US', (date.length > 10 ? options2 : options1) as Intl.DateTimeFormatOptions)
        ) : null
      }
      </td>
    )
  }
}
