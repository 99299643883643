import { LinkIcon } from 'components/icons/link';
import { RSSFeedIcon } from 'components/icons/rssfeed';
import { TwitterIcon } from 'components/icons/twittersmall';
import { isEmpty } from 'lodash'
import React from 'react'

export interface ArticleProps {
    articleSource: any,
    sourceTitle: any,
    siteName: any,
}

export class SourceArticle extends React.Component<ArticleProps> {
    public render() {
      return (
        <div className="mobile-source-row">
          <div className="mobile-source-type-icon">{this.sourceNamePic(this.props.articleSource)}</div>
          {!isEmpty(this.props.articleSource) &&
            this.props.siteName !== this.props.articleSource &&
            <div className="mobile-source-name-text">
              {this.props.siteName}
            </div>}
        </div>
      );
    }

    private sourceNamePic = (siteName: any) => {
      if (siteName === 'Twitter') {
        return <div><TwitterIcon svgwidth={16} svgheight={16}/></div>
        } else if (siteName === 'RSS Feed') {
        return <div><RSSFeedIcon svgwidth={16} svgheight={16}/></div>
      } else {
        return <div><LinkIcon svgwidth={16} svgheight={16}/></div>
      }
    }
}
