import React from 'react'
import { NO_BOOST_LEVEL } from 'shared_server_client/constants'

export class ReservedCell extends React.Component<any> {
  constructor(p: any) {
    super(p)
  }

  public render() {
    return <div onClick={(e) => this.toggleReserved(e)} className="like-active">
            {this.props.dataItem.is_reserved ?
            <a>Move to Upcoming</a>
            :
            <a>
              <i className="fa fa-retweet"></i>
              Mark as Reserve
            </a>
            }
        </div>
  }

  private toggleReserved = (e: any) => {
    const newReserved: boolean = !this.props.dataItem.is_reserved
    if (this.props.onChange) {
      this.props.onChange({
        dataItem: this.props.dataItem,
        syntheticEvent: e,
        field: 'is_reserved',
        value: newReserved,
      })

      if (newReserved) {
        this.props.onChange({
          dataItem: this.props.dataItem,
          syntheticEvent: e,
          field: 'boost_level',
          value: NO_BOOST_LEVEL,
        })
      }
    }
    if (this.props.onToggle) {
      this.props.onToggle(this.props.dataItem, newReserved)
    }
  }
}
