import * as React from 'react'

export interface ArticleProps {
    articleDate: string,
    onChange?: (event: {
        articleDate: any;
        syntheticEvent: React.SyntheticEvent<any>;
        fieldName?: string;
        value?: any;
    }) => void;
}

interface ActiveState {
  fieldName: string
}

export interface ActiveProps extends ArticleProps {
  fieldName?: string
}

export class DateArticle extends React.Component<ActiveProps, ActiveState> {
  public render() {
    const options1 = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
    }

    const options2 = {
      ...options1,
      hours: 'numeric',
      minutes: 'numeric',
    }
    const date = this.props.articleDate

    return (
      new Date(date).toLocaleDateString('en-US', (date.length >= 10 ? options2 : options1) as Intl.DateTimeFormatOptions)
    )
  }
}
