import { GridCell } from '@progress/kendo-react-grid'
import React from 'react'
import { SourceTypes } from '../../../shared_server_client/constants'

export class SourceIdentifierCell extends GridCell {
    public render() {
      let sourceUrl
      switch (this.props.dataItem.source_type) {
        case SourceTypes.twitter:
          sourceUrl = `https://www.twitter.com/${this.props.dataItem.source_url}`
          break
        case SourceTypes.pocket:
        case SourceTypes.rss:
        case SourceTypes.upcontent:
        case SourceTypes.googleNews:
          sourceUrl = this.props.dataItem.source_url
          break
        case SourceTypes.facebook:
          if (this.props.dataItem.source_options && JSON.parse(this.props.dataItem.source_options).url) {
            sourceUrl = JSON.parse(this.props.dataItem.source_options).url
          } else {
            sourceUrl = null
          }
          break
        default:
          sourceUrl = null
      }
      return (
        <td>
          {sourceUrl ? <a href={sourceUrl} target="_blank" rel="noopener">{sourceUrl}</a> : null}
        </td>
      );
    }
  }
