import { GridCellProps } from '@progress/kendo-react-grid'
import React from 'react'
import { ToggleOff } from '../../../components/icons/toggleoff'
import { ToggleOn } from '../../../components/icons/toggleon'

interface ActiveCellState {
  fieldName: string
}

export interface ActiveCellProps extends GridCellProps {
  fieldName?: string
  onToggle?: any
}

export class ActiveCell extends React.Component<ActiveCellProps, ActiveCellState> {
  public static getDerivedStateFromProps(props, state) {
    if (props.dataItem[state.fieldName] === state.isActive) { return null }
    return {...state, isActive: props.dataItem[state.fieldName]}
  }

  constructor(props: ActiveCellProps) {
    super(props)
    const fieldName: string = this.props.fieldName || 'is_active'
    this.state = {
      fieldName,
    }
  }

  public render() {
    return (
      <td >
        {!this.props.dataItem.is_archived &&
        <div onClick={(e) => this.toggle(e)} className="toggle-active">
          <button className="toggle-button" style={{background: 'none', border: 'none', outline: 'none'}}>
            {this.props.dataItem[this.state.fieldName] ? <ToggleOn/> : <ToggleOff/>}
          </button>
        </div>}
      </td>
    )
  }

  private toggle = (e: any) => {
    const newIsActive: boolean = !this.props.dataItem[this.state.fieldName]
    // for scheduled content only: the server entity needs id to contain
    // contentType & recordId (ie 'scheduledText-325') // only scheduledContent
    // items have a 'typeId' property => this only affects activeCell for scheduledContent
    if (this.props.dataItem.typeId) {
      this.props.dataItem.id = this.props.dataItem.typeId
    }
    if (this.props.onChange) {
      // Only deactivate duplicates when status changed from active to inactive.
      // Will ignore the duplicates when activate the article
      if (this.props.dataItem.duplicates && this.props.dataItem.duplicates.length > 0 && !newIsActive) {
        this.props.dataItem.duplicates.forEach((item: any) => {
          this.props.onChange({
            dataItem: item,
            syntheticEvent: e,
            field: this.state.fieldName,
            value: newIsActive,
          })
        })
      }
      this.props.onChange({
        dataItem: this.props.dataItem,
        syntheticEvent: e,
        field: this.state.fieldName,
        value: newIsActive,
      })
    }

    if (this.props.onToggle) {
      this.props.onToggle(this.props.dataItem, !this.props.dataItem[this.state.fieldName])
    }
  }
}
