import { EditButtonLight } from 'components/icons/lightedit';
import React from 'react';

export interface EditCardProps {
  onClick: any,
}
export class EditCard extends React.Component<EditCardProps, object> {

  constructor(props: EditCardProps) {
    super(props)
  }

  public render() {
    return (
        <div className="title-edit">
          <button onClick={this.props.onClick}>
            <EditButtonLight />
          </button>
        </div>
    )
  }
}
