import { GridColumn } from '@progress/kendo-react-grid'
import {ProgressBar} from '@progress/kendo-react-progressbars';
import classnames from 'classnames'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { FeatureUnavailableComponent, FeatureUnavailableComponentName } from 'components/feature-unavailable'
import { DateTimeCell } from 'components/gridcells/datetime'
import { HeaderComponent } from 'components/header/component'
import { RasaContext } from 'context'
import { BaseClientEntity } from 'generic/baseClientEntity'
import { Dataset } from 'generic/dataset'
import { EditableDropdownCell, EditCellProps } from 'generic/editCell'
import * as GenericRedux from 'generic/genericRedux'
import {RasaDataGrid} from 'generic/rasaDataGrid'
import * as GA from 'google-analytics'
import React, {Component} from 'react'
import { Button } from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { ModalSize } from 'shared/modals/constants'
import * as BillingPlan from 'shared_server_client/types/billing_plan'
import {Loading} from '../../loading';
import { UpgradeButton } from '../../upgrade/button';
import { SECTIONS_ADDITIONAL_FEATURES, UPGRADE_BUTTON_COLOR } from '../../upgrade/constants';
import '../styles.css'
import { SourceSection } from '../types'
import { AddSourceSectionModal } from './modals'

type SourceSectionProps = GenericRedux.AllComponentPropsWithModal<SourceSection>

interface SourceSectionState {
  sectionsCount: number,
  canAddSection: boolean,
  communityId: string,
  editId: string,
  editName: string,
  forceReload: boolean,
  hasFeatureAccess: boolean,
  maxSectionsCount: number,
  plan?: BillingPlan.BillingPlan,
  sections: any[],
  isSaveButtonDisabled: boolean,
  loading: boolean,
}
class SourceSectionsComponent extends Component<SourceSectionProps, SourceSectionState> {
  public static contextType = RasaContext
  private entityName: string = 'section'
  private sharedStore: SharedStore
  constructor(props: SourceSectionProps) {
    super(props)
    this.state = {
      sectionsCount: 0,
      canAddSection: true,
      communityId: '',
      editId: null,
      editName: '',
      forceReload: false,
      hasFeatureAccess: false,
      maxSectionsCount: 0,
      sections: [],
      isSaveButtonDisabled: true,
      loading: true,
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
    ]).then(([activeCommunity]) => {
      this.getSections(activeCommunity.communityId)
      .then((sections) => {
        this.setState({
          sectionsCount: sections.length,
          canAddSection: sections.length < activeCommunity.billingInfo.usageStats.currentPlanMaxSections,
          communityId: activeCommunity.communityId,
          hasFeatureAccess: activeCommunity.billingInfo.usageStats.currentPlanMaxSections > 0,
          sections,
          maxSectionsCount: activeCommunity.billingInfo.usageStats.currentPlanMaxSections,
          plan: activeCommunity.billingInfo.currentPlan,
          loading: false,
        })
      })
    })
  }

  public render() {
    return (
      this.state.loading ? <Loading size="64" /> :
      !this.state.hasFeatureAccess ?
        <FeatureUnavailableComponent {...this.setupUpgradeComponentProps()} /> :
      <div className="article-sections">
        <div className="header-container">
          <AddSourceSectionModal
            sectionData={this.state.sections}
            data={this.props.modals}
            closeModal={this.props.closeModal}
            createOrUpdate={this.createOrUpdate}
            saveModal={this.props.saveModal}
            updateModal={this.props.updateModal}
            name={this.state.editName}
            size={ModalSize.Small} />
          <HeaderComponent
            title={'SOURCES'}
            subTitle={'Sections'}
          />
          <this.sectionsLimitReached/>
        </div>
        <div>
            <RasaDataGrid
                // Rasa Properties for entity, datasetName etc
                entityName="section"
                datasetName="sections"
                editDefaultState={true}
                forceReload={this.state.forceReload}
                // events
                sortable={false}
                pageable={false}
                data={[]}>
              <GridColumn title="Name"  field="name" width={350} />
              <GridColumn title="# Upcoming"  field="last_week_total" width={200} />
              <GridColumn field="created" title="Create Date" cell={DateTimeCell} width={350} />
              <GridColumn cell={this.SectionEditCell}/>
            </RasaDataGrid>
          </div>
      </div>
    )
  }
  protected SectionEditCell = (props: EditCellProps) => {
    return <EditableDropdownCell {...props} canEdit={true} onEdit={this.editClick}
            onArchive={this.onArchive} canArchive={true}/>
  }

  protected sectionsLimitReached = () => {
    const maxSectionsCount = BillingPlan.getCurrentPlanMaxSections(this.state.plan)
    const isUnlimited = maxSectionsCount === BillingPlan.UNLIMITED_VALUE || maxSectionsCount === -1
    const maxLimitReached = !isUnlimited &&
      this.state.sectionsCount >= maxSectionsCount
    return <div className="section-right-side">
      <div className="section-button-container">
        {!isUnlimited ?
          <div className="div-progress-bar">
            <ProgressBar
              min={0}
              className={`progress-bar${maxLimitReached ? '-red' : ''}`}
              max={maxSectionsCount}
              value={this.state.sectionsCount}
              labelVisible={false}/>
            <div className="progress-bar-label">
              <div>Sections used</div>
              <div>{this.state.sectionsCount}/{maxSectionsCount}</div>
            </div>
          </div> : null}
        {maxLimitReached ?
          <div>
            <UpgradeButton color={UPGRADE_BUTTON_COLOR.WHITE} showIcon={true}
                           source={GA.UpgradeSource.AddSource} text="Upgrade" />
          </div> :
          <div className={classnames('add-sections-button-text-section')}>
            <Button
              className={classnames('add-sections-button', this.state.canAddSection ? '' : 'disabled')}
              onClick={() =>
                this.props.openModal(AddSourceSectionModal.key, null)}>
              <span className="sections-button-text">
                Add New Section
              </span>
            </Button>
          </div>}
      </div>
      {maxLimitReached ?
        <div className="max-limit-description">
          You've reached your sections limit, please upgrade your plan to add a new section.
        </div> : null}
    </div>
  }
  private editSectionUrl = (p: any) => `${DashboardMenuOption.content}/editsection?id=${p.id}`

  private editClick = (dataItem: any) => {
    this.props.push(this.editSectionUrl(dataItem))
  }
  private onArchive = (props: any, event: any) => {
    this.context.entityMetadata.getEntityObject(this.entityName).then(
      (entityObject: BaseClientEntity) => {
        entityObject.setRecordId(this.state.communityId, props.dataItem.id)
        entityObject.archive()
        .then(() => {
          this.setState({
            forceReload: true,
            sectionsCount: this.state.sectionsCount - 1,
          }, () => {
            this.getSections(this.state.communityId)
          .then((sections) => {
            this.setState({
              sections,
              forceReload: false,
            })
          })
          })
        })
      })
  }
  private createOrUpdate = (payload: any) => {
    return this.context.entityMetadata
    .getEntityObject(this.entityName, this.state.communityId, null)
    .then((sourceSectionEntityObject: BaseClientEntity) => {
      sourceSectionEntityObject.setRecordId(this.state.communityId, this.state.editId ? this.state.editId : null)
      sourceSectionEntityObject.data.name = payload.name
      sourceSectionEntityObject.data.community_identifier = this.state.communityId
      return sourceSectionEntityObject.save()
        .then(() => {
          this.props.resetModal(AddSourceSectionModal.key)
          this.setState({
            forceReload: true,
            editId: null,
            editName: '',
            sectionsCount: this.state.editId ? this.state.sectionsCount : this.state.sectionsCount + 1,
          }, () => {
            this.setState({forceReload: false})
          })
          this.getSections(this.state.communityId)
          .then((sections) => {
            this.setState({
              canAddSection: sections.length < this.state.maxSectionsCount,
              sections,
            })
          })
        })
    })
  }
  private getSections = (community) => {
    return new Dataset()
    .loadCommunityDataset('sections', community)
    .then((response) => {
      return response[0] || []
    })
  }

  private setupUpgradeComponentProps = () => {
    return {
      loadComponent: FeatureUnavailableComponentName.Upgrade,
      source: GA.UpgradeSource.UseSection,
      upgradeComponentProps: {
        source: GA.UpgradeSource.UseSection,
        features: SECTIONS_ADDITIONAL_FEATURES,
        description: 'Unlock more templating and features when you upgrade your plan',
      },
    }
  }
}

export const ContentPoolSourceSections = GenericRedux.registerNewComponentWithModals<SourceSection>(
  SourceSectionsComponent,
  'source_sections',
  [AddSourceSectionModal.key],
  )
