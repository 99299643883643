import { GridCellProps } from '@progress/kendo-react-grid'
import React from 'react'

interface AddIconCellState {
  checked: boolean
}

export interface AddIconCellProps extends GridCellProps {
  onToggle?: any
}

export class AddIconCell extends React.Component<AddIconCellProps, AddIconCellState> {
  public static getDerivedStateFromProps(props, state) {
    if (props.dataItem.checked === state.checked) { return null }
    return {...state, checkd: props.dataItem.checked}
  }

  constructor(props: AddIconCellProps) {
    super(props)
    this.state = {
      checked: false,
    }
  }

  public render() {
    return (
      <td className="center">
        {!this.props.dataItem.is_archived &&
        <div onClick={(e) => this.toggle(e)}>
          <button className={`add-icon-button ${this.props.dataItem.checked ? 'check' : 'plus'}`}>
            <div className="feature-icon">{this.props.dataItem.checked ? <i className="fas fa-check"></i> : <i className="fas fa-plus"></i>}</div>
          </button>
        </div>}
      </td>
    )
  }

  private toggle = (e: any) => {
    if (this.props.onChange) {
      this.props.onChange({
        dataItem: this.props.dataItem,
        syntheticEvent: e,
        field: 'checked',
        value: !this.props.dataItem.checked,
      })
    }

    if (this.props.onToggle) {
      this.props.onToggle(this.props.dataItem, !this.props.dataItem.checked)
    }
  }
}
